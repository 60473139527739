import React from "react"

export default function JaPolicy231208({ theme }) {
    return (
        <div className={`policy ${theme === "dark" ? "dark" : ""}`}>
            <p className="title-1">個人情報処理方針</p>
            <p>最終更新日：2023年12月8日</p>
            <p>発効日：2021年7月2日</p>
            <p>CELEBe（以下、「本プラットフォーム」という）へようこそ。</p>
            <p>本プラットフォームは、CELEBE KOREA CO., LTD（以下、「CELEBe」または「当社」という）が提供して管理します。</p>
            <p>
                当社はあなたの個人情報を保護して尊重するために最善の努力を尽くしています。本個人情報処理方針（以下、「本方針」という）では、当社があなたより収集するか、あなたが当社に提供する個人情報に関する当社の処理方法を説明しています。本方針に同意しない場合、あなたは本プラットフォームをご利用いただけません。
            </p>
            <p>
                当社があなたの個人情報を取り扱う方法についてご質問がある場合は、
                <b
                    onClick={() => {
                        window.location.href = "celebe://deeplink/qna"
                    }}
                >
                    CELEBeアプリのQ&A
                </b>
                を通じお問い合わせください。
            </p>
            <p className="title-2">要約</p>
            <p className="title-3">当社はどのような個人情報を収集するか？</p>
            <p>
                当社はあなたがアカウントを生成して本プラットフォームにコンテンツをアップロードする際に提供する情報を収集・処理します。上記の情報には、あなたの本プラットフォームの使用に関する技術及び行動情報が含まれます。さらに、当社はあなたがアカウントを生成していない状態でアプリケーション（以下、「アプリ」という）をダウンロードして、本プラットフォームとのインタラクションをする場合も、Cookieを通じあなたの非識別情報を収集します。
            </p>
            <p className="title-3">あなたの個人情報はどのように利用するか？</p>
            <p>
                当社は本プラットフォームをあなたに提供して、さらに本プラットフォームを改善及び管理するためにあなたの情報を活用します。当社は何よりもあなたにフィードを推薦して、本プラットフォームを改善・開発して、あなたの安全を確保する目的であなたの情報を利用します。適切な場合、当社はあなたにカスタマイズされた広告を掲載して、本プラットフォームを宣伝するためにあなたの個人情報を利用します。
            </p>
            <p className="title-3">当社は誰とあなたの情報を共有するか？</p>
            <p>
                当社は本プラットフォームを提供する際に役立つ第三者、つまりクラウドサービスプロバイダーのような第三者とあなたの情報を共有します。また、当社の事業パートナー、CELEBeと同じ企業グループに属する会社、コンテンツ審議サービスプロバイダー、測定サービスプロバイダー、広告主及び分析サービスプロバイダーとあなたの情報を共有します。ただし、広告主にあなたを識別できる情報は提供しません。法律によって要求される場合は、当社は法的に拘束力のある裁判所の命令に従って、司法機関、規制当局及び第三者とあなたの情報を共有します。
            </p>
            <p className="title-3">あなたの情報を保持する期間</p>
            <p>
                当社は、サービスを提供するために必要な期間の間にあなたの情報を保持します。あなたにサービスを提供するために、あなたの情報を保持する適法な事業目的があるか、当社にその情報を保持する法的義務のある期間に限り、あなたの情報を保持します。また、当社はあなたの情報が法的請求の成立、行使または防御のために必要とされた場合は、あなたの情報を保持します。
            </p>
            <p className="title-3">当社が本方針の変更事項をあなたに通知する方法</p>
            <p>
                一般的に、当社は、本プラットフォーム上での通知を通じ本方針の主な変更事項をすべての利用者に通知します。ただし、あなたは本方針を定期的に見直し、変更事項を確認する必要があります。当社は、本方針の効力発生日を反映した「最近の改正」日付も、本方針の上部に更新します。あなたは本プラットフォームに接続するか、本プラットフォームを利用することで、あなたが本方針を熟知してあなたの個人情報に関するあなたの権利及び当社があなたの個人情報を収集・利用及び処理する方法を理解することを認めます。
            </p>
            <p className="title-2">1. 当社が利用する個人情報の類型</p>
            <p>当社が収集して利用するあなたの情報は次のとおりです。</p>
            <p className="title-3">あなたのプロフィール情報</p>
            <p>
                あなたは、本プラットフォームに加入した時点の情報を当社に提供して、ユーザー名、パスワード、（該当する場合のみ）生年月日、電子メール及び/又は電話番号、あなたがユーザープロフィールで開示する情報及びあなたの写真またはプロフィールビデオなどがこれに該当します。
            </p>
            <p className="title-3">ユーザーコンテンツと行動情報</p>
            <p>
                当社は、あなたが本プラットフォームで生成したコンテンツを処理して、処理対象のコンテンツには、あなたが設定した優先事項（言語選択など）、あなたがアップロードした写真、ビデオ、及びあなたのコメントが含まれます（以下、「ユーザーコンテンツ」という）。当社は、あなたが参加するアンケート、チャレンジ、大会を通じ情報を収集します。さらに、当社は、あなたの本プラットフォームのご利用に関する情報、すなわち、あなたが本プラットフォームに参加する方法に関する情報を収集し、当社があなたに表示するコンテンツとあなたがインタラクションする方式、あなたが見る広告とビデオ、あなたが遭遇する問題、あなたの好きなコンテンツ、あなたが保存したコンテンツ、及びあなたがフォローイングするユーザーなどがこれに含まれます。
            </p>
            <p>
                また、当社はあなたにカスタマイズされたコンテンツを提供するために、あなたの関心事、性別などあなたの好みを推測します。当社はあなたのコンテンツを他のユーザーに宣伝して、あなたのプロフィールがさらなるコラボレーションの機会を提供するかどうかを調べるために、あなたのフォロワー関連情報及びアップロードしたコンテンツに対してあなたが受け取った「いいね」と反応情報を処理します。適切な場合、当社はあなたにカスタマイズされた広告を提供して、新しいサービス及び機会を案内するために前述した情報を利用します。
            </p>
            <p className="title-3">第三者からの提供情報</p>
            <p>
                あなたは、第三者をもって、または他のプラットフォームを通じ、特定の情報を当社と共有するように選択でき、あなたが本プラットフォームを利用することで、当社が自動的に第三者から情報を収集することができます。当社は以下のように第三者から提供される情報について詳細に規定しています。
            </p>
            <p className="title-3">事業パートナー</p>
            <p>
                あなたがあなたのソーシャルネットワークのアカウント情報を使用して本プラットフォームを利用すると登録した場合、あなたはあなたのユーザー名及び公開プロフィールを当社に提供することになるか、またはあなたのソーシャルネットワークが同情報を当社に提供することを許可することになります。また、当社は、あなたのアプリID、アクセストークン、及び参照URLなど特定の情報をあなたのソーシャルネットワークと共有します。
            </p>
            <p className="title-3">広告主と広告ネットワーク</p>
            <p>
                当社は、あなたに本プラットフォームを無料で提供するために、随時に当社のプラットフォームにカスタマイズされた広告を掲載することがあります。当社はあなたの関心事を最大限に推測してより関連性の高い公告を提供するために、あなたより収集した情報とともに、あなたと本プラットフォーム及び他の第三者のサイト間でのインタラクションに関する情報を利用します。当社は前述した情報を通じ、あなたが訪問したウェブサイト、あなたがダウンロードしたアプリ、あなたの購入内訳などを把握して、あなたの今後の関心事を予測して、当社のプラットフォームでの広告がどれだけ効果的なのかを評価します。当社はCookie及び当社のアプリ上の類似した技術を利用して当社のプラットフォームで広告をして、あなたが訪問するサイトを運営する第三者から受け取った類似した情報を使用して、前述した情報を収集します。
            </p>
            <p className="title-3">あなたに関連して収集する技術情報</p>
            <p>
                当社は、あなたがアカウントを作成せずにアプリを利用する場合を含めて、あなたが本プラットフォームを利用する際に、あなたから特定の情報を自動に収集します。収集対象情報には、あなたのIPアドレス、ブラウジング履歴（つまり、あなたが本プラットフォームで見たコンテンツ）、移動通信会社、タイムゾーンの設定、広告用識別子、及びあなたが使用しているアプリのバージョンなどが含まれます。また、あなたのデバイスモデル、デバイスのオペレーティングシステム、ネットワークタイプ、デバイスID、スクリーン解像度、MCC及びオペレーティングシステムなど、あなたが本プラットフォームにアクセスするために使用しているデバイスに関する情報も含まれます。あなたが複数の端末を使用してログインする場合、当社はあなたのプロフィール情報を利用して、複数の端末上であなたの活動を識別することができます。
            </p>
            <p className="title-3">位置情報</p>
            <p>
                当社は、位置情報の保護及び利用等に関する法律により、以下のように個人位置情報を処理します。あなたに最適化されたカスタマイズされたコンテンツや広告を提供するために、あなたの同意を得て、GPS基盤の位置情報を収集することができます。収集されたあなたの位置情報は、あなたに適切なCELEBe経験を提供します。あなたが端末の設定で位置情報の送信を許可しない場合、位置情報は送信されません。ただし、会社は顧客が位置情報の送信を許可していない場合においても、IPアドレスなどのネットワーク情報から顧客のおおよその位置を推定する場合があります。ユーザーの事前同意なしに個人位置情報を第三者に提供しません。
            </p>
            <p className="title-3">他のユーザーを探して、友達を招待する</p>
            <p>
                あなたは「友達を探す」機能を利用して、本プラットフォームで他のユーザーを見つけるかどうかを選択できます。「友達を探す」機能を使用すると、あなたはあなたの携帯電話の連絡先やSNSの友達リストにある友達のうち、本プラットフォームを使用している友達を見つけてフォローイングすることができます。さらに、あなたはこの機能を利用して連絡先上の友達を本プラットフォームに参加するように招待することができます。管轄区域に従って、当社はあなたが保持している連絡先情報を利用してあなたから招待された人に、あなたの携帯電話の連絡先リスト内であなたのCELEBeプロフィールを閲覧するように招待するSMS、電子メール、または第三者のSNSメッセージ送信するオプションを提供します。
            </p>
            <p className="title-3">ポイント購入</p>
            <p>
                あなたがアプリ内でポイント購入機能を提供する特定の管轄区域にお住まいの場合は、当社の仮想アイテム方針（Virtual Items
                Policy）をご理解ください。あなたの購入は、あなたのApple iTunes のアカウントまたは Google Play
                のアカウントで行われます。該当取引に関して、当社はあなたから金融情報または決済情報を収集しません。該当情報の取扱については、各アプリストアの約款及び通知をご確認ください。これにより、当社はあなたのアカウントに正確な金額のポイントを入金でき、あなたの購入履歴、購入時点及び使用金額を記録します。
            </p>
            <p className="title-3">1. Cookie</p>
            <p>
                当社、当社のベンダー及びサービスプロバイダーは、Cookie及びその他類似した技術（例：ウェブビーコン(web
                beacons)、フラッシュクッキー(flash
                cookies)など）（以下、「Cookie」という）を利用して情報を自動に収集し、あなたがクリックしたウェブページ、及びあなたが本プラットフォームをどのように利用するかを測定及び分析して、あなたの本プラットフォームの利用経験を改善し、当社のサービスを改善して、本プラットフォーム及びあなたの各デバイスであなたにカスタマイズされた広告を提供します。Cookieは、本プラットフォームが特定の機能を提供できるようにする小さいファイルであり、お使いのデバイスにインストールされています。
                ウェブビーコンは、イメージ内に挿入されたとてもに小さいイメージやデータで、「ピクセルタグ（pixel
                tag）」や「クリア（clear）GIF」とも呼ばれ、Cookie、ページを見た時間と日付、ピクセルタグのインストールされたページの説明、及びあなたのコンピュータや機器から収集された類似した情報を認識します。あなたは、本プラットフォームを利用することにより、Cookieの利用に同意することになります。
            </p>
            <p>
                さらに、当社は、当社の事業パートナー、広告ネットワーク、及びその他の広告ベンダー及びサービスプロバイダー（分析ベンダー及びサービスプロバイダーを含む）がCookieを通じあなたのオンライン活動に関する情報を収集することを許可します。このような第三者は、当社のプラットフォーム及びオンライン上で、あなたの関心事、お好み、及び特徴に合わせて広告を掲載するためにこれらの情報を利用することがあります。当社は、前述した第三者の個人情報処理に対して責任を負いません。本方針では、第三者の情報処理実務を扱いません。
            </p>
            <p>
                あなたは、あなたのブラウザの設定を調整してCookieを拒否または無効にすることができます。各ブラウザが異なるため、あなたのブラウザが提供する指示や説明をご参照ください。特定種類のCookieを拒否または無効にするには、追加アクションが必要になる場合があります。たとえば、ブラウザとモバイルアプリの機能に差があるため、ブラウザ上のカスタマイズされた広告に使用されるCookieをブロックするか、モバイルアプリ上のカスタマイズされた広告をブロックするためには、異なる措置を取ることがありますが、このような場合、あなたのデバイス設定やモバイルアプリの許容権限を調整しアクションを取ることができます。また、あなたのブロック選択はあなたがブロック時に使用する特定のブラウザまたはデバイスに限って適用されるため、各ブラウザまたはデバイスに対して個別のブロックアクションが必要になる場合があります。Cookieを拒否、無効、削除する場合、本プラットフォームの一部機能はあなたに提供されない場合があります。
            </p>
            <p className="title-3">1. あなたの個人情報を利用する方法</p>
            <p>
                当社は、当社があなたから収集する情報を以下の方法で利用します。 当社サービスの変更事項をあなたに通知するために、
                あなたにユーザーサポートを提供するために、
                あなたが提供されるコンテンツを個人化して、あなたの関心事に該当するカスタマイズされたコンテンツをあなたに提供するために、
                あなたが他のユーザーとユーザーコンテンツを共有してインタラクション可能にするために
                あなたがメッセンジャーサービスを利用することを選択した場合、当社のメッセンジャーサービスを有効化するために
                あなたが仮想アイテムプログラムに参加できるようにするために あなたとコミュニケーションするために
                本プラットフォームの濫用、本プラットフォーム上の不正行為及び不法行為を検出するために
                当社のコミュニティガイドラインに違反するユーザーコンテンツ、メッセージ及び関連メタデータ、及びその他の不適切なコンテンツを検討して、あなたの安全及びセキュリティを保護するために、
                コンテンツがあなたとあなたのデバイスに最も効果的な方法で提供されるように措置するために、
                本プラットフォームを改善、広報、開発して、本プラットフォーム上で人気のトピック、ハッシュタグ及びキャンペーンを宣伝するために、
                安定性及びセキュリティを確保するためにデータ分析を実施して、本プラットフォームのテストを進めるために、
                あなたが本プラットフォームのインタラクション機能を利用できるように許容するために、
                他のユーザーが「他の友達を探す」機能や携帯電話の連絡先を介してあなたを見つけられるようにするなど、あなたが本プラットフォームで他人と交流できるようにするために、
                （法的に必要な場合）あなたが本プラットフォームを利用できる適正年齢であることを確認するために、
                あなたにカスタマイズされた広告を提供するために、
                （あなたの管轄区域で位置基盤サービスが提供される場合）あなたに位置基盤サービスを提供するために、 当社の規約及び方針を執行するために、
                障害処理を含む本プラットフォームを管理するために、
            </p>

            <p className="title-3">1. あなたの個人情報を共有する方法</p>
            <p>
                当社は、次のような第三者とあなたの情報を共有しますが、共有の範囲はあなたが居住する管轄地域によって異なります。 事業パートナー
                あなたがあなたのソーシャルネットワークのアカウント情報を利用して本プラットフォームに登録する場合、あなたはあなたのユーザー名と公開されたプロフィールを当社に提供するか、あなたのソーシャルネットワークが情報を当社に提供することを許可することになります。また、当社は、あなたのアプリID、アクセストークン、及び参照URL（referring
                URL）などのような特定情報をあなたのソーシャルネットワークと共有します。
                あなたが他のソーシャルメディアのプラットフォームにコンテンツを共有することを選択した場合はビデオ、ユーザー名、及びそれに付随する文字が該当プラットフォームに共有されて、インスタントメッセンジャーを介して共有する場合はコンテンツへのリンクが共有されます。
                支給決済サービスプロバイダー
                あなたがポイント購入を選択した場合、必要によって、当社はあなたより同意を得て取引を容易にするために、支給決済サービスのプロバイダーとあなたの情報を共有します。当社は、あなたを特定して、あなたがお支払いを行うと同時に、あなたのアカウントに正確な金額のポイントを入金するために取引IDを共有します。
                サービスプロバイダー
                当社は、クラウドサービスプロバイダー及びコンテンツ審議サービスプロバイダーなど、本プラットフォームが安全で楽しい場所になるように当社の事業を支援するサービスプロバイダーに情報及びコンテンツを提供します。
                分析サービスプロバイダー
                当社は、本プラットフォームの最適化と改善を支援する分析サービスプロバイダーを利用できます。第三者である分析サービスプロバイダーは、カスタマイズされた広告に関する支援も当社に提供することができます。
                広告主と広告ネットワーク
                当社は、広告を見るかクリックしたプラットフォームの利用者数とその種類を示すために、広告主及び第三者である測定サービスプロバイダーと情報を共有します。ただし、あなたを識別できる情報は共有しません。当社は測定サービス提供者とあなたのデバイスIDを共有して、あなたの本プラットフォーム上の活動を他のウェブサイトでのあなたの活動と関連付け、その後、あなたの関心事になれる広告を表示するために前述した情報を利用します。
                CELEBe Koreaグループ
                当社は、本プラットフォームの改善及び最適化を含む違法利用を防止しユーザーをサポートして、本プラットフォームの最適化、改善、その他を提供するために、CELEBe
                Koreaグループ内の他のメンバー、子会社又は関連会社とあなたの情報を共有できます。 法律の執行
                当社は、法的に要求されるか、次のような目的のために合理的に要求される場合、あなたの情報を司法機関、公的機関またはその他の機関と共有します。
                法的義務、手続または要求に従うために
                違反の可能性に関する調査を含む当社のサービス規約及びその他の契約、方針及び基準の執行のために、セキュリティ、詐欺、または技術的問題の検出、防止または処理のために、法律に基づく要求または許可されるところによって、当社、当社のユーザー、第三者及び公共の権利、財産または安全保護のために（詐欺防止及び信用リスクの減少のために他社及び機関と情報を交換することを含む）
                公開プロフィール
                あなたのプロフィールが公開されている場合、あなたのコンテンツはあなたのプラットフォーム上の誰にも表示されることがあり、さらにあなたの友達やフォロワーだけではなく、検索エンジン、コンテンツの統合管理者(Content
                aggregator)及びニュースサイトがあなたのコンテンツに接続するか、あなんたのコンテンツを共有できることを参考にしてください。あなたは、各ビデオをアップロードする時、該当ビデオが見られる権限を持つ人を変更でき、あなたは設定で「個人アカウント」の設定を変更してプロフィール公開に関する基本設定を非公開に変更できます。
                売却または合併
                当社は、法律で規定されていることように、あなたの同意を得るか、あなたに通知した後、以下のように、あなたの情報を第三者に開示することがあります。
                （清算、破産、その他の理由を問わず）当社がいかなる事業または資産を売却または買入れる場合、当社は当該事業または資産の潜在的な買受人か売渡人にあなたの情報を開示します。当社が売却、買入、合併されるか、他社もしくは事業によって買収されるか、他社または事業とパートナー関係になる場合、もしくは当社の資産の全部または一部を売却する場合、利用者情報が当該取引に基づき移転される資産に含めることがあります。
            </p>
            <p className="title-3">1. あなたの権利</p>
            <p>
                あなたはCELEBeに加入することによって、あなたのプロフィール情報にアクセスして編集することができます。あなたはあなたがアップロードしたユーザーコンテンツを削除することができます。また、当社はあなたが調整できるさまざまな設定ツールを提供して、あなたはあなたのビデオを見られる人、あなたにメッセージを送られる人、あなたのビデオにコメントを付けられる人などを制御できます。必要に応じて、あなたは設定からあなたのアカウントをすべて削除できます。ツールの使用方法について質問がある場合や、お住まいの国であなたが持っている権利について知りたい場合は、CELEBeアプリのQ&Aを通じご連絡ください。
            </p>
            <p className="title-3">1. 個人情報に関するセキュリティ</p>
            <p>
                当社は、あなたの情報が本方針に従って安全に処理されるように措置を取ります。ただし、残念にもインターネットを介した情報転送は、セキュリティが完全に保証されていません。当社は暗号化などの方法であなたの個人情報を保護しますが、本プラットフォームを通じ送信されるあなたの情報に対するセキュリティは保証できません。情報伝達のリスクに伴う責任はあなたの負担となります。
                当社は、さまざまなリスクの可能性、あなた及び他のユーザーの権利及び自由の深刻性及びリスクに対応するレベルのセキュリティを確保するために、適切な技術的及び管理的措置を取っています。当社は技術的及び管理的な措置を維持して、その措置を随時に補完し、当社システムの全体的なセキュリティを改善します。
                当社のプラットフォームには、当社パートナーのネットワーク、広告主及び関係会社のウェブサイトへのリンク、または同ウェブサイトからのリンクを含めます。あなたが当該ウェブサイトへのリンクをアクセスする場合、当該ウェブサイトが独自のプライバシー方針を保有していて、当社は当該方針に対して責任を負わないことをご参照ください。該当ウェブサイトに情報を提供する前に、そのウェブサイトの方針をご確認ください。
            </p>
            <p className="title-3">1. 個人情報の保持期間</p>
            <p>
                当社は、あなたにサービスを提供するために2年間あなたの情報を保有します。あなたにサービスを提供するための目的であなたの情報を保有する必要がなくなったら、当社は該当情報を保有する適法な事業目的が存在する期間に限り、あなたの情報を保持します。ただし、当社の法的義務の履行または当社の法的請求の成立、行使または防御のために、当社が前述の期間を超えてあなたの情報を保持できる場合があります。
            </p>
            <p className="title-3">1. 児童情報</p>
            <p>
                CELEBeは14歳未満の子供は対象と致しません。現地の規制により、14歳以上の年齢制限が適用される場合もありますので、あなたに適用される現地の個人情報処理方針で詳細情報をご確認ください。当社が基準年齢未満のお子様の個人情報を保有または収集したと思われる場合は、CELEBeアプリのQ&Aを通じご連絡ください。
            </p>
            <p className="title-3">1. ユーザーからの苦情</p>
            <p>
                当社があなたの個人情報を処理する方法に対して不満を申し立てたい場合は、CELEBeアプリのQ&Aを通じまずお問い合わせください。あなたのご要望にできるだけ迅速に対応するよう努力いたします。前述の事項はあなたが関連するプライバシーの規制機関に苦情を申し立てる権利を侵害しません。
            </p>
            <p className="title-2">10. 変更</p>
            <p>
                一般的に、当社は本プラットフォームを通じ公知する方法で本方針の主な変更事項をすべてのユーザーに通知します。ただし、あなたには変更を確認するために本方針を定期的に確認する必要があります。当社は本方針の効力発生日を反映した「最近改正」の日付も本方針の上部に更新します。あなたが方針の改正日付後に本プラットフォーム継続して接続するか、これを利用することはあなたが改正された方針に同意したことを意味します。あなたが改正された方針に同意しなければ、本プラットフォームへのアクセス及び利用を中止する必要があります。個人情報処理方針の以前バージョンを確認するためにはこちらをクリックしてください。
            </p>
            <p className="title-2">11. 連絡先</p>
            <p>
                本方針に関する質問、コメント、及びリクエストについては、CELEBeアプリのQ&Aを通じお問い合わせください。
                特定の管轄区域に適用される追加約款
                あなたが本件サービスにアクセスするか、本件サービスを利用する管轄地域に適用される特定の管轄区域の追加約款（以下「追加約款」という）の条項と本方針の本文条項が衝突する場合、該当管轄区域の追加約款が優先して適用されます。
                韓国
                あなたが韓国で当社のサービスを利用している場合、下記の追加規約を適用します。追加条項と本方針の本文条項が矛盾する場合は追加規約が優先して適用されます。
                あなたの個人情報を共有する方法
                本方針の本文には、当社が当社の広告及びマーケティングキャンペーンの一環として、あなたの選択に応じて当社のプラットフォームで宣伝目的に掲載するユーザー生成コンテンツ及びビデオコンテンツなどの情報を利用する場合、あなたの同意によって、当該情報に含まれたあなたの個人情報が当該広告またはマーケティングコンテンツが提供される者に開示されることがあります。
                情報保有
                当社はあなたが同意した収集目的が達成されたか、またはあなたが同意した利用期間または本方針に明示された利用期間が満了した個人情報を破棄します。ただし、当社は該当する場合は次を含むが、これに限らない法定期間中にあなたの個人情報を保持し続けます。
                電子商取引等における消費者保護に関する法律： あなたの注文取消記録、購入アイテムに対する支払記録、当社の商品/サービスの提供記録：5年
                消費者不満または紛争処理記録：3年 広告及び表示関連記録：6ヶ月 通信秘密保護法： あなたの当社ウェブサイトへの訪問記録：3ヶ月
                個人情報の破棄 当社は関連部門で復旧できない方法であなたの個人情報を破棄します。 あなたの権利 情報及びアカウントに対する権利
                あなたは、当社が保有したあなたの個人情報に対する閲覧、不正確な情報の訂正、削除、処理中断、及びアカウントの削除を要求する権利を保有します。あなたは、CELEBeアプリのQ&Aまたはhttps://forms.gle/U28UTEu8wE8WJLxx9に連絡してあなたの権利を行使することができます。
                情報セキュリティ
                当社は、当社が保有した情報への不正アクセスまたは不正変更、不正開示または破棄等からCELEBeとユーザーを保護するために最善の努力を尽くしています。そのために当社は技術的、管理的、物理的な保護措置を施行中で、情報保護のための内部管理計画の策定、必要な人員に限ってアクセス権の付与、個人情報処理施設へのアクセス制御などを含む技術的・管理的・物理的な措置を実施しています。
                方針の変更
                当社は、本方針を変更する場合、改正された方針の効力発生日より少なくとも7日前（改正が本方針によるあなたの権利または義務に相当な影響を及ぼす場合は30日前）に当社のウェブサイトまたは、サービスページを通じあなたに変更を告知します。
                児童情報 CELEBeは14歳未満のお子様を対象と致しません。 個人情報の委託及び/又は国外への移転
                当社は、場合によって、あなたの同意を受けるかあなたに通知して、あなたの情報を当社の関連会社、クラウドサービスのプロバイダー、ITサービスのプロバイダー及びデータセンターに委託して、受託会社の一部は国外に所在しています（リストはこちらをご参照ください）。あなたの情報を受け取って処理する主体は、国内外の規制に遵守します。個人情報を利用及び保存して個人情報の保護のために可能な限りすべての物理的及び技術的な措置を取ることを約束します。あなたに本件サービスを提供する上で個人情報の移転が不要な場合、あなたはCELEBeアプリのQ&Aに連絡し個人情報の移転を拒否することができます。
                その他のお問い合わせ：CELEBeアプリのQ&Aを利用してお問い合わせください。
            </p>
        </div>
    )
}
