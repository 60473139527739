import { useEffect, useState } from "react"

import step1 from "../../../assets/point-guide/step1_KOR.png"
import PHstep1 from "../../../assets/point-guide/ph/step1_PH.png"
import INstep1 from "../../../assets/point-guide/in/step1_IN.png"
import INHINDIstep1 from "../../../assets/point-guide/in/step1_IN_HINDI.png"
import THstep1 from "../../../assets/point-guide/th/step1_TH.png"
import VNstep1 from "../../../assets/point-guide/vn/step1_VN.png"
import IDstep1 from "../../../assets/point-guide/id/step1_ID.png"
import Back from "../../../assets/drawable-xxhdpi/backbutton.svg"

export default function Step1() {
    const [country, setCountry] = useState("ko")
    const [lang, setLang] = useState("ko")
    const [loading, setLoading] = useState(false)

    useEffect(() => {
        const uri = new URL(window.location)

        const langValue = uri.searchParams.get("lang")
        if (langValue) setLang(langValue)

        const countryValue = uri.searchParams.get("country")
        if (countryValue) setCountry(countryValue)

        setLoading(true)
    }, [])

    const getImageSrcByCountryAndLang = (country, lang) => {
        if (country === "in" && lang === "hi") {
            return INHINDIstep1
        }

        const countryImageMap = {
            ko: step1,
            ph: PHstep1,
            in: INstep1,
            th: THstep1,
            vn: VNstep1,
            id: IDstep1,
        }

        return countryImageMap[country] || step1
    }

    const imageSrc = getImageSrcByCountryAndLang(country, lang)

    return loading ? (
        <div className="point-guide" style={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
            {/* <img
                src={Back}
                style={{ position: "absolute", top: 10, right: 10, width: 30, height: 30, zIndex: 10, padding: 1 }}
                alt={""}
                onClick={() => window.history.back()}
            /> */}
            <img src={imageSrc} style={{ width: "100%", maxWidth: "800px", height: "auto", marginBottom: "-0.5px" }} alt={""} />
        </div>
    ) : (
        <div></div>
    )
}
