import React from "react"
import JaPolicy231209 from "./JaPolicy231208"

export default function policyJa({ theme, date }) {
    const policy = () => {
        switch (date) {
            case "231208":
                return <JaPolicy231209 />
            default:
                return <JaPolicy231209 />
        }
    }
    return <div className={`policy-ko ${theme === "dark" ? "dark" : ""}`}>{policy()}</div>
}
