import React, { useEffect, useState } from "react"
import "./styles.scss"
import google from "../../assets/drawable-xxhdpi/googleplay.png"
import apple from "../../assets/drawable-xxhdpi/appstore.png"
import ad from "../../assets/ad.mp4"

export default function Home() {
    const [isMobile, setIsMobile] = useState(false)

    const checkIsMobile = () => {
        setIsMobile(window.innerWidth <= 768)
    }

    useEffect(() => {
        checkIsMobile()

        window.addEventListener("resize", checkIsMobile)

        return () => window.removeEventListener("resize", checkIsMobile)
    }, [])

    return (
        <div className="home-wrap">
            <div className="video-wrap">
                <video
                    key={isMobile ? "mobile-video" : "pc-video"}
                    muted={true}
                    autoPlay={true}
                    loop={true}
                    playsInline
                    className={isMobile ? "mobile" : "pc"}
                >
                    <source src={ad} type="video/mp4" />
                </video>
                <div className="info-wrap">
                    <div className="shadow" />
                    <h1>숏폼의 새로운 방향</h1>
                    <div>
                        <a href="https://abr.ge/koig5p" target="_blank" rel="noreferrer">
                            <img src={google} alt="" />
                        </a>
                        <a href="https://abr.ge/koig5p" target="_blank" rel="noreferrer">
                            <img src={apple} alt="" />
                        </a>
                    </div>
                </div>
            </div>
        </div>
    )
}
