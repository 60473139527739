import React from "react"
import "./AdvertisingInformationJa.scss"

export default function AdvertisingInformationJa({ theme }) {
    return (
        <div className={`ad-content ${theme === "dark" ? "dark" : ""}`}>
            <p className="title-1">広告性情報の受信同意</p>
            <p className="title-2">広告性情報の受信同意</p>
            <p>
                本同意は、CELEBe
                KOREAが収集した以下の項目を利用してお客様に電子的な伝送媒体を通じマーケティング等の目的で個人に最適化された広告又は情報を送信することに関する広告性情報の受信同意です。お客様は本同意に拒否する権利がありますし、本同意に拒否した場合に受ける別途の不利益はありません。
            </p>
            <table className="info-table">
                <tbody>
                    <tr>
                        <th>利用目的</th>
                        <td>電子的な伝送媒体を通じ、CELEBeの商品またはサービスに対する個人向け広告・情報の転送</td>
                    </tr>
                    <tr>
                        <th>利用項目</th>
                        <td>
                            氏名、電子メール、性別、
                            <br />
                            生年月日、関心事、顧客ID、デバイス識別ID、IPアドレス
                        </td>
                    </tr>
                    <tr>
                        <th>保有及び利用期間</th>
                        <td>同意の撤回前または退会時まで</td>
                    </tr>
                </tbody>
            </table>
            <p className="title-3">ご案内</p>
            <ul>
                <li>CELEBeの退会前までは、上記の広告性情報の受信同意に従って、多様な情報及び広告を受け取ることができます。</li>
                <li>広告情報の受信に同意しない場合でも、お客様を保護するために案内メールを送信することがあります。</li>
                <li>
                    法令による個人情報の収集/利用、契約の履行/便宜増進のための個人情報の処理委託及び個人情報の処理に関する一般事項は、サービスの個人情報処理方針に従います。
                </li>
                <li>同意の撤回（受信拒否）は、[マイページ] &gt; [設定] &gt; [通知]で可能です。</li>
            </ul>
        </div>
    )
}
