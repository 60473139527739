import { useEffect, useState } from "react"
import { useNavigate } from "react-router-dom"

import "./step2.scss"

import PHstep2 from "../../../assets/point-guide/ph/step2_PH.png"
import INstep2 from "../../../assets/point-guide/in/step2_IN.png"
import INHINDIstep2 from "../../../assets/point-guide/in/step2_IN_HINDI.png"
import THstep2 from "../../../assets/point-guide/th/step2_TH.png"
import VNstep2 from "../../../assets/point-guide/vn/step2_VN.png"
import IDstep2 from "../../../assets/point-guide/id/step2_ID.png"
import metaMask from "../../../assets/point-guide/metamask.png"
import huobi from "../../../assets/point-guide/huobi.png"
import Back from "../../../assets/drawable-xxhdpi/backbutton.svg"

export default function Step2() {
    const navigate = useNavigate()

    const [country, setCountry] = useState("ko")
    const [lang, setLang] = useState("ko")
    const [loading, setLoading] = useState(false)

    useEffect(() => {
        const uri = new URL(window.location)

        const langValue = uri.searchParams.get("lang")
        if (langValue) setLang(langValue)

        const countryValue = uri.searchParams.get("country")
        if (countryValue) setCountry(countryValue)

        setLoading(true)
    }, [])

    const getImageSrcByCountryAndLang = (country, lang) => {
        if (country === "in" && lang === "hi") {
            return INHINDIstep2
        }

        const countryImageMap = {
            ph: PHstep2,
            in: INstep2,
            th: THstep2,
            vn: VNstep2,
            id: IDstep2,
        }

        return countryImageMap[country] || PHstep2
    }

    const imageSrc = getImageSrcByCountryAndLang(country, lang)

    return loading ? (
        country === "ko" ? (
            <div
                className="point-guide"
                style={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    backgroundColor: "#735EF1",
                    maxWidth: "800px",
                    margin: "0 auto",
                }}
            >
                {/* <img
                    src={Back}
                    style={{ position: "absolute", top: 10, right: 10, width: 30, height: 30, zIndex: 10, padding: 1 }}
                    alt={""}
                    onClick={() => window.history.back()}
                /> */}
                <div style={{ display: "flex", flexDirection: "column", marginTop: "40px" }}>
                    <div style={{ textAlign: "center", color: "#fff", fontSize: "24px", marginBottom: "5px" }}>유 의 사 항</div>
                    <div style={{ width: "70%", borderTop: "5px solid #fff", margin: "0 auto" }}></div>
                    <ul
                        className="point-guide"
                        style={{ listStyle: "inside", margin: "20px 30px", color: "#fff", lineHeight: "23px", fontSize: "12px" }}
                    >
                        <li style={{ textIndent: "-12px", marginLeft: "20px" }}>
                            FANC가 상장 되어있는 거래소 중 원화(KRW) 입금이 가능한 Bithumb 기준입니다.
                        </li>
                        <li style={{ textIndent: "-12px", marginLeft: "20px" }}>
                            ETH는 국내외 다양한 거래소에서 매수 및 전송이 가능하오니, 편의에 따라 타 거래소를 이용하셔도 무방합니다.다만, 트래블룰
                            체결 및 fanc wallet으로 전송 가능 여부는 거래소 마다 정책이 상이 하오니 이 점 양지하시어 거래하시기를 바랍니다.
                        </li>
                    </ul>
                </div>
                <div style={{ backgroundColor: "#fff", borderRadius: "10px", padding: "10px", margin: "20px" }}>
                    <div style={{ marginBottom: "10px", fontSize: "20px" }}>
                        <p>방법1) 개인지갑 (Meta Mask) 이용</p>
                    </div>
                    <div style={{ padding: "10px 20px" }}>
                        <img src={metaMask} style={{ width: "100%" }} alt={""} />
                    </div>
                    <div style={{ color: "#523EE8", marginBottom: "20px", textAlign: "center" }}>
                        <p>
                            Bithumb에서 매수해야하는 최소 ETH : <span style={{ borderBottom: "1px solid #523EE8" }}>0.02개</span>
                        </p>
                    </div>
                    <div style={{ textAlign: "center" }}>
                        <div
                            style={{
                                width: "60%",
                                margin: "10px auto",
                                borderRadius: "8px",
                                backgroundColor: "rgba(93,83,225,0.1)",
                                boxShadow: "0px 5px 5px 0px rgba(0,0,0,0.2)",
                                fontSize: "12px",
                                padding: "5px",
                            }}
                            onClick={() => navigate("/point-guide/2/meta/1")}
                        >
                            <p>
                                <span className="menu_title" style={{ borderBottom: "1px solid black" }}>
                                    {"1) Meta Mask 연결"}
                                </span>
                            </p>
                        </div>
                        <div
                            style={{
                                width: "60%",
                                margin: "10px auto",
                                borderRadius: "8px",
                                backgroundColor: "rgba(93,83,225,0.1)",
                                boxShadow: "0px 5px 5px 0px rgba(0,0,0,0.2)",
                                fontSize: "12px",
                                padding: "5px",
                            }}
                            onClick={() => navigate("/point-guide/2/meta/2")}
                        >
                            <p>
                                <span className="menu_title" style={{ borderBottom: "1px solid black" }}>
                                    {"2) ETH 매수"}
                                </span>
                            </p>
                        </div>
                        <div
                            style={{
                                width: "60%",
                                margin: "10px auto",
                                borderRadius: "8px",
                                backgroundColor: "rgba(93,83,225,0.1)",
                                boxShadow: "0px 5px 5px 0px rgba(0,0,0,0.2)",
                                fontSize: "12px",
                                padding: "5px",
                            }}
                            onClick={() => navigate("/point-guide/2/meta/3")}
                        >
                            <p>
                                <span className="menu_title" style={{ borderBottom: "1px solid black" }}>
                                    {"3) ETH 충전"}
                                </span>
                            </p>
                        </div>
                    </div>
                </div>
                <div style={{ backgroundColor: "#fff", borderRadius: "10px", padding: "10px", margin: "20px" }}>
                    <div style={{ marginBottom: "10px", fontSize: "20px" }}>
                        <p>방법2) 글로벌 거래소(Huobi) 이용</p>
                    </div>
                    <div style={{ padding: "10px 20px" }}>
                        <img src={huobi} style={{ width: "100%" }} alt={""} />
                    </div>
                    <div style={{ color: "#523EE8", marginBottom: "20px", textAlign: "center" }}>
                        <p>
                            Bithumb에서 매수해야하는 최소 ETH : <span style={{ borderBottom: "1px solid #523EE8" }}>0.0212개</span>
                        </p>
                    </div>
                    <div style={{ textAlign: "center" }}>
                        <div
                            style={{
                                width: "60%",
                                margin: "10px auto",
                                borderRadius: "8px",
                                backgroundColor: "rgba(93,83,225,0.1)",
                                boxShadow: "0px 5px 5px 0px rgba(0,0,0,0.2)",
                                fontSize: "12px",
                                padding: "5px",
                            }}
                            onClick={() => navigate("/point-guide/2/huobi/1")}
                        >
                            <span className="menu_title" style={{ borderBottom: "1px solid black" }}>
                                1) ETH 매수
                            </span>
                        </div>
                        <div
                            style={{
                                width: "60%",
                                margin: "10px auto",
                                borderRadius: "8px",
                                backgroundColor: "rgba(93,83,225,0.1)",
                                boxShadow: "0px 5px 5px 0px rgba(0,0,0,0.2)",
                                fontSize: "12px",
                                padding: "5px",
                            }}
                            onClick={() => navigate("/point-guide/2/huobi/2")}
                        >
                            <span className="menu_title" style={{ borderBottom: "1px solid black" }}>
                                {" "}
                                2) Huobi로 ETH 전송
                            </span>
                        </div>
                        <div
                            style={{
                                width: "60%",
                                margin: "10px auto",
                                borderRadius: "8px",
                                backgroundColor: "rgba(93,83,225,0.1)",
                                boxShadow: "0px 5px 5px 0px rgba(0,0,0,0.2)",
                                fontSize: "12px",
                                padding: "5px",
                            }}
                            onClick={() => navigate("/point-guide/2/huobi/3")}
                        >
                            <span className="menu_title" style={{ borderBottom: "1px solid black" }}>
                                3) ETH 충전
                            </span>
                        </div>
                    </div>
                </div>
            </div>
        ) : (
            <div style={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
                <img src={imageSrc} style={{ width: "100%", maxWidth: "800px", height: "auto", marginBottom: "-0.5px" }} alt={""} />
            </div>
        )
    ) : (
        <div></div>
    )
}
