import React from "react"

export default function AdvertisingInformationEn({ theme }) {
    return (
        <div className={`ad-content ${theme === "dark" ? "dark" : ""}`}>
            <p className="title-1">Consent for Receiving Promotional Information</p>
            <p style={{ marginBottom: 10 }}>
                This consent pertains to your agreement to receive promotional information from CELEBe Korea via electronic communication channels,
                utilizing the following information collected by CELEBe Korea. This information will be used to send you personalized advertisements
                or information for marketing purposes. You have the right to refuse this consent, and there will be no separate disadvantages if you
                choose to refuse.
            </p>
            <table>
                <tbody>
                    <tr>
                        <td>
                            <p>Purpose of Use</p>
                        </td>
                        <td>
                            <p>
                                Sending personalized advertisements and information about CELEBe's products or services via electronic communication
                                channels
                            </p>
                        </td>
                    </tr>
                    <tr>
                        <td>
                            <p>Information Used</p>
                        </td>
                        <td>
                            <p>Name, email address, gender,</p>
                            <p>date of birth, interests, customer ID, device identification ID, IP address</p>
                        </td>
                    </tr>
                    <tr>
                        <td>
                            <p>Retention and Use Period</p>
                        </td>
                        <td>
                            <p>Until the withdrawal of consent or until you unsubscribe</p>
                        </td>
                    </tr>
                </tbody>
            </table>
            <p style={{ margin: "10px 0px", fontWeight: "bold" }}>Notice</p>
            <ul style={{ listStyle: "inside", margin: "10px", color: "#fff", lineHeight: "23px", fontSize: "12px" }}>
                <li style={{ textIndent: "-12px", marginLeft: "10px" }}>
                    Until you withdraw from CELEBe's services, you may receive various information and advertisements based on the consent for
                    receiving promotional information as mentioned above.
                </li>
                <li style={{ textIndent: "-12px", marginLeft: "10px" }}>
                    Even if you do not agree to receive promotional information, informational emails may still be sent to ensure customer protection.{" "}
                </li>
                <li style={{ textIndent: "-12px", marginLeft: "10px" }}>
                    The collection/use of personal information in accordance with laws, personal information processing delegation for the
                    performance/convenience improvement of contracts, and general matters related to personal information processing are subject to
                    the service's privacy policy.
                </li>
                <li
                    style={{ textIndent: "-12px", marginLeft: "10px" }}
                >{`You can withdraw consent (opt-out) in the 'My Page' > 'Settings' > 'Notifications' menu.`}</li>
            </ul>
        </div>
    )
}
