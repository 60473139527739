import React from "react"

export default function CommunityGuidelineJa({ theme }) {
    return (
        <div className={`guideline ${theme === "dark" ? "dark" : ""}`}>
            <p className="title-1">コミュニティガイドライン</p>
            <br />
            <p>最終更新日 2023.05</p>
            <p className="title-3">はじめに</p>
            <p>
                CELEBeの目的は、すべてのユーザーの創造性を発揮し楽しみを提供することです。
                すべてのユーザーが自分の個性を活かして創造性を明らかにし他のユーザーに共有して、新しい世界を経験し世界中の人々とコミュニケーションできるグローバルプラットフォームを作るよう努めています。
                CELEBeが成長しながら、お互いを支える環境を維持するために最善を尽くしています。
                コミュニティガイドラインは、CELEBe内での規範と行動方針を規定しています。
                許容されたコンテンツと許容されていないコンテンツのポリシーを提供し、CELEBeを誰もが楽しむためのスペースにします。
                CELEBeの優先順位は、安全、多様性、包容、真実性です。
                ユーザーが安全な環境の中で快適に各自の個性を尊重し、お互いにインスピレーションを与えるコンテンツの制作に努めています。
                CELEBeコミュニティガイドラインは、CELEBe内のすべてのユーザーとコンテンツに適用されます。
                独自の審査を通じ、問題のあるコンテンツが報告される前に、ガイドラインに基づいてコンテンツを優先的に提供します。
                コミュニティのガイドラインに違反していると見なされるコンテンツやアカウントを見つけた場合は、アプリ内の機能を通じ直接申告できます。
                ガイドラインに違反するすべてのコンテンツ（ビデオ、オーディオ、コメント、テキストなど）は削除されることがあり、コンテンツのパフォーマンスを収益として支払うC2E（Create
                To Earn）対象から除外されることがあります。
                継続的または重大なコンテンツのガイドライン違反が見つかったアカウント及びデバイスは、一時的に停止または使用を禁止することがあります。
                この決定は他のプラットフォームとオフライン情報を考慮して決定され、必要に応じてアカウントは関連する司法当局に報告されることがあります。
                CELEBeのアルゴリズムは信頼と安全を念頭に置いて設計されています。
                スパムや審査中のビデオをはじめ、一般ユーザーに不快感やショックを与える可能性があるビデオなど、一部のコンテンツに対しては検索結果を変更するか、メインメディア内の露出制限をかけることがあります。
                ガイドラインに従って削除する必要のある一部のコンテンツは、誰かにとって価値のあるコンテンツかもしれないことを常に認識しています。
                したがって、教育的、ドキュメンタリー的、科学的または芸術的なコンテンツ、風刺性コンテンツ、虚構基盤のコンテンツ、反対意見の提示、及びニュースとして価値があるか、または社会的重要性を持っているトピックに関する意見表現など、特定状況に限って例外を許容します。
                ユーザーが自らの創造性を指揮し、楽しみを感じられる安全なプラットフォームとして維持されるように、新しいリスクと行動規範に合わせてコンテンツガイドラインを随時更新しています。
            </p>
            <p className="title-3">本文</p>
            <ul style={{ paddingLeft: "15px" }}>
                <li>
                    <p>暴力的極端主義</p>
                </li>
                <li>
                    <p>嫌い行動</p>
                </li>
                <li>
                    <p>違法活動と規制対象商品</p>
                </li>
                <li>
                    <p>暴力的で不快なコンテンツ</p>
                </li>
                <li>
                    <p>自殺、自害及び危険行為</p>
                </li>
                <li>
                    <p>ハラスメント及び嫌がらせ</p>
                </li>
                <li>
                    <p>大人の全裸露出及び性的活動</p>
                </li>
                <li>
                    <p>未成年者の安全</p>
                </li>
                <li>
                    <p>真実性及び真正性</p>
                </li>
                <li>
                    <p>個人情報の侵害</p>
                </li>
                <li>
                    <p>知的財産権の侵害</p>
                </li>
                <li>
                    <p>プラットフォームセキュリティ</p>
                </li>
            </ul>
            <p className="title-3">▶ 暴力的極端主義</p>
            <p>
                CELEBeプラットフォーム内外での暴力に対して強硬な立場を維持しています。
                人々がCELEBeを通じ脅威や暴力を助長したり、危険な人物や団体を奨励することは禁止されています。
                公衆の安全に脅威がある場合、または特定のアカウントがCELEBe以外の暴力を助長または美化するために利用される場合、そのアカウントは一時的に停止または使用が禁止される可能性があります。
                必要に応じて、これらの脅威は関連する司法当局に報告されることがあります。
                CELEBeを効果的に保護するために、暴力的で極端な人物や組織を特定する際に、他のプラットフォームやオフライン情報を参照することもできます。
                CELEBe内でそのような人物や団体が見つかった場合、そのアカウントは削除されることがあります。 &nbsp;
            </p>
            <p>&nbsp;</p>
            <p>
                <u>暴力の脅威と扇動</u>
            </p>
            <p>
                CELEBe内で暴力を助長したり参加したりする人物や組織は許容しません。
                暴力を擁護、指示、または助長する行為を暴力扇動とみなし、深刻な害を引き起こす恐れのある暴力の脅威や扇動は許容しません。
                大量虐殺犯、連鎖殺人犯及び強姦犯、憎悪団体、犯罪組織、テロ組織及びその他の民間人を対象とした非国家武装団体を含む危険人物と組織は、CELEBeから削除することがあります。
            </p>
            <p>
                <u>
                    したがって、次のコンテンツは、投稿、アップロード、ストリーミング、共有を禁止します。リストはほんの一部に過ぎず、すべてのケースを含めているわけではありません。
                </u>
            </p>
            <ul style={{ paddingLeft: "15px" }} style={{ paddingLeft: "15px" }}>
                <li>
                    <p>個人やグループを対象に身体を傷つける意図を披歴</p>
                </li>
                <li>
                    <p>他人に物理的な暴力を擁護するか、暴力行動を助長するイメージとか発言 &nbsp;</p>
                </li>
                <li>
                    <p>他人に暴力を犯すことをお勧めする念願や条件についての発言&nbsp;</p>
                </li>
                <li>
                    <p>個人やグループを脅かしたり脅迫したりする意図で特定の場所まで武器を運搬してくれとの要求&nbsp;</p>
                </li>
                <li>
                    <p>暴力を助長する意図で武器の製造及び使用方法の指示&nbsp;</p>
                </li>
            </ul>
            <p>&nbsp;</p>
            <p>
                <u>テロ組織</u>
            </p>
            <p>
                テロリストとテロ組織は、政治的、宗教的、民族的または理念的な目標を達成するために民間人を対象に暴力を行使、脅威、及び/または深刻な犯罪（反人道主義的犯罪など）を行う非国家活動主体を意味します。
            </p>
            <p>&nbsp;</p>
            <p>
                <u>犯罪組織&nbsp;</u>
            </p>
            <p>
                犯罪組織は、暴力犯罪（例えば、殺人、強姦、強盗、暴力）、密売（例えば、人身売買、長期、麻薬、武器密売）、誘拐、経済犯罪（例えば、恐怖、喧嘩、詐欺、マネーロンダリング）またはサイバー犯罪を含む重大な犯罪に関与する超国家的、国家的、または地域内のグループを意味します。
            </p>
            <p>
                <u>
                    したがって、次のコンテンツは、投稿、アップロード、ストリーミング、共有を禁止します。リストはほんの一部に過ぎず、すべてのケースを含めているわけではありません。
                </u>
            </p>
            <ul style={{ paddingLeft: "15px" }}>
                <li>
                    <p>危険な人物及び/または組織を賞賛、奨励、美化、または支持するコンテンツ&nbsp;</p>
                </li>
                <li>
                    <p>危険な組織への参加を奨励するか、人を募集するコンテンツ &nbsp;&nbsp;</p>
                </li>
                <li>
                    <p>
                        危険な人物及び/または組織を象徴する名前、シンボル、ロゴ、旗、スローガン、制服、ジェスチャー、敬礼、絵、肖像画、歌、音楽、歌詞、またはその他のオブジェクトを含むコンテンツ
                    </p>
                </li>
            </ul>
            <p className="title-3">▶ 憎悪行動</p>
            <p>
                CELEBeは、いかなる差別も容認できず、多様性と包括性を重視するプラットフォームです。
                憎悪発言や行動を含むコンテンツを許容せず、憎悪発言に関するポリシーに違反するか、CELEBe以外の憎悪発言に関わったアカウントは、一時的に停止または使用を禁止します。
                &nbsp;
            </p>
            <p>&nbsp;</p>
            <p>
                <u>保護対象をターゲットとする攻撃</u>
            </p>
            <p>
                憎悪の発言と行動は、以下の保護対象に該当する個人またはグループを攻撃、脅威、暴力の助長、または他の方法で非人間化するコンテンツとして定義します。
            </p>
            <ul style={{ paddingLeft: "15px" }}>
                <li>
                    <p>人種</p>
                </li>
                <li>
                    <p>民族</p>
                </li>
                <li>
                    <p>出身国</p>
                </li>
                <li>
                    <p>宗教</p>
                </li>
                <li>
                    <p>出身階層（Caste）&nbsp;</p>
                </li>
                <li>
                    <p>性的指向&nbsp;</p>
                </li>
                <li>
                    <p>性別</p>
                </li>
                <li>
                    <p>ジェンダー</p>
                </li>
                <li>
                    <p>ジェンダーアイデンティティ</p>
                </li>
                <li>
                    <p>深刻な病気&nbsp;</p>
                </li>
                <li>
                    <p>障害</p>
                </li>
                <li>
                    <p>移民資格&nbsp;</p>
                </li>
            </ul>
            <p>
                <u>
                    したがって、次のコンテンツは、投稿、アップロード、ストリーミング、共有を禁止します。リストはほんの一部に過ぎず、すべてのケースを含めているわけではありません。
                </u>
            </p>
            <ul style={{ paddingLeft: "15px" }}>
                <li>
                    <p>保護対象を含む個人またはグループを対象とした憎悪コンテンツ</p>
                </li>
                <li>
                    <p>彼らは身体的、精神的または道徳的に劣っていると主張&nbsp;</p>
                </li>
                <li>
                    <p>彼らに対する暴力の要求または正当化&nbsp;</p>
                </li>
                <li>
                    <p>彼らは犯罪者だと主張&nbsp;</p>
                </li>
                <li>
                    <p>彼らを動物、無生物または他の人間以下の存在と呼ぶ&nbsp;</p>
                </li>
                <li>
                    <p>彼らに対する排除、分離、または差別を助長または正当化</p>
                </li>
                <li>
                    <p>保護対象内の個人またはグループに犯される暴力を描写するコンテンツ</p>
                </li>
            </ul>
            <p>&nbsp;</p>
            <p>
                <u>差別的な俗語</u>
            </p>
            <p>
                差別的な俗語は、民族、人種、及び上記で列挙された保護対象を貶す意図で使用される卑下するような言葉で定義されます。
                非常に不快な用語が広がることを防ぐために、差別的な俗語は削除される可能性があります。
                例外は、そのような用語が変更されて使用されたり、自分を指すとき（たとえば、歌で）、または軽蔑の意味がない場合です。 &nbsp;
            </p>
            <p>
                <u>
                    したがって、次のコンテンツは、投稿、アップロード、ストリーミング、共有を禁止します。リストはほんの一部に過ぎず、すべてのケースを含めているわけではありません。
                </u>
            </p>
            <ul style={{ paddingLeft: "15px" }}>
                <li>
                    <p>差別的な俗語を使用または含むコンテンツ&nbsp;</p>
                </li>
            </ul>
            <p>&nbsp;</p>
            <p>
                <u>憎悪理念</u>
            </p>
            <p>
                憎悪理念とは、保護対象という理由で人々に敵対心を表わす理念を意味します。
                憎悪の理念は包括的で支持的なCELEBeの価値に反しており、これらの理念を促進するコンテンツは削除される可能性があります。 &nbsp;
            </p>
            <p>
                <u>
                    したがって、次のコンテンツは、投稿、アップロード、ストリーミング、共有を禁止します。リストはほんの一部に過ぎず、すべてのケースを含めているわけではありません。
                </u>
            </p>
            <ul style={{ paddingLeft: "15px" }}>
                <li>
                    <p>憎悪理念を称賛、助長、美化または支持するコンテンツ&nbsp;</p>
                </li>
                <li>
                    <p>
                        憎悪理念に関連する名前、シンボル、ロゴ、フラグ、スローガン、ユニフォーム、ジェスチャー、敬礼、絵画、肖像画、歌、音楽、歌詞、またはその他のオブジェクトを含むコンテンツ&nbsp;
                    </p>
                </li>
                <li>
                    <p>保護対象に被害を与えた証拠が多いにもかかわらず、過去の暴力的事件を否定するコンテンツ&nbsp;</p>
                </li>
                <li>
                    <p>特定のグループを保護対象と比較して優位性を主張するコンテンツ&nbsp;</p>
                </li>
                <li>
                    <p>憎悪理念を正当化するために使用される陰謀論を扱うコンテンツ</p>
                </li>
            </ul>
            <p className="title-3">▶違法活動及び規制対象物品</p>
            <p>
                CELEBeは、法律や規制に違反する活動が不可能なプラットフォームを作成するよう努めています。
                人間の搾取を含む犯罪活動の描写、助長または促進を含む、特定の規制対象物品の取引、販売、宣伝及び使用を禁止します。
                多くの国や地域で違法または規制されている活動やアイテムに関連している場合は、投稿されている管轄地域でその活動またはアイテムが合法的であっても一部のコンテンツが削除されることがあります。
            </p>

            <p>
                <u>犯罪活動</u>
            </p>
            <p>
                犯罪活動には、盗難、暴行、人間の搾取、偽造、その他の有害行動など、法律で罰せられる広範な活動が含まれます。
                このような行為が日常化、模倣の対象となるとか、または許容されないように、犯罪活動を容認または奨励するコンテンツは削除されることがあります。
            </p>
            <p>
                <u>
                    したがって、次のコンテンツは、投稿、アップロード、ストリーミング、共有を禁止します。リストはほんの一部に過ぎず、すべてのケースを含めているわけではありません。
                </u>
            </p>
            <ul style={{ paddingLeft: "15px" }}>
                <li>
                    <p>暴力や拉致などの身体的傷害を描写または奨励するコンテンツ&nbsp;</p>
                </li>
                <li>
                    <p>虚偽の報告など他人の安全を脅かすコンテンツ&nbsp;</p>
                </li>
                <li>
                    <p>人身売買、担保労働（借金返済のための強制労働）、家庭奴隷、性売買または売春を含む人間の搾取を描写または奨励するコンテンツ</p>
                </li>
                <li>
                    <p>密猟または違法野生動物取引を描写または奨励するコンテンツ&nbsp;</p>
                </li>
                <li>
                    <p>違法に取得または偽造された商品の購入、販売、取引を提案するか、要求を含むコンテンツ&nbsp;</p>
                </li>
                <li>
                    <p>犯罪行為を犯す方法を教えてくれるコンテンツ</p>
                </li>
            </ul>
            <p>
                <u>武器</u>
            </p>
            <p>
                CELEBeは、銃器、弾薬、銃器アクセサリー、または爆発性武器の描写、宣伝、または取引を許容しません。
                また、無機類の製造方法に関するコンテンツも禁止されています。
                博物館コレクションの一部であるか、警察官の武器、軍事パレード、射撃場など、安全で管理された環境で使用される武器関連コンテンツは許容されます。
            </p>
            <p>
                <u>
                    したがって、次のコンテンツは、投稿、アップロード、ストリーミング、共有を禁止します。リストはほんの一部に過ぎず、すべてのケースを含めているわけではありません。
                </u>
            </p>
            <ul style={{ paddingLeft: "15px" }}>
                <li>
                    <p>銃器、銃器アクセサリー、弾薬または爆発性武器を示すコンテンツ&nbsp;</p>
                </li>
                <li>
                    <p>銃器、付属品、弾薬、爆発性武器またはその製造方法の購入、販売、取引を提案または要求するコンテンツ</p>
                </li>
            </ul>

            <p>
                <u>麻薬、規制薬、アルコール及びタバコ </u>

                <u>&nbsp;</u>
            </p>
            <p>
                CELEBeは、麻薬またはその他の規制薬物の描写、奨励、または取引を許容しません。
                タバコ及びアルコール製品の取引もCELEBe内で禁止されています。 &nbsp;
            </p>
            <p>
                <u>
                    したがって、次のコンテンツは、投稿、アップロード、ストリーミング、共有を禁止します。リストはほんの一部に過ぎず、すべてのケースを含めているわけではありません。
                </u>
            </p>
            <ul style={{ paddingLeft: "15px" }}>
                <li>
                    <p>麻薬及び麻薬消費を描写または奨励するか、または他人に麻薬及び他の規制薬物の製造、使用、または取引を推奨するコンテンツ&nbsp;</p>
                </li>
                <li>
                    <p>
                        •
                        麻薬及びその他の規制薬物、アルコールまたはタバコ（電子タバコ製品を含む）製品の購入、販売、取引を提案または要求するコンテンツ&nbsp;
                    </p>
                </li>
                <li>
                    <p>違法または規制薬物の購入方法に関する情報を提供するコンテンツ&nbsp;</p>
                </li>
                <li>
                    <p>違法アルコール製品の製造を描写または奨励するコンテンツ&nbsp;</p>
                </li>
                <li>
                    <p>合法的な薬物の誤用や取るための薬物の手作り製造方法を描写または奨励するコンテンツ&nbsp;</p>
                </li>
            </ul>
            <p>&nbsp;</p>
            <p>
                <u>ギャンブル</u>
            </p>
            <p>
                CELEBeは、ギャンブルサービスを促進するコンテンツ、カジノ、スポーツベット、またはその他の商業賭博行為の広告と見なせるコンテンツを許容しません。&nbsp;
            </p>
            <p>
                <u>
                    したがって、次のコンテンツは、投稿、アップロード、ストリーミング、共有を禁止します。リストはほんの一部に過ぎず、すべてのケースを含めているわけではありません。
                </u>
            </p>
            <ul style={{ paddingLeft: "15px" }}>
                <li>
                    <p>
                        カジノ、スポーツベット、ポーカー、宝くじ、ギャンブル関連ソフトウェアやアプリ、またはその他のギャンブルサービスを促進するコンテンツ&nbsp;
                    </p>
                </li>
            </ul>
            <p>&nbsp;</p>
            <p>
                <u>詐欺行為</u>
            </p>
            <p>
                CELEBeを通じユーザーの信頼を悪用して経済的及び個人的なダメージを与えることは許容しません。
                詐欺や資産の盗難計画を含めて、不法に経済的または個人的な利益を得るために人を欺くコンテンツは削除されることがあります。
            </p>
            <p>
                <u>
                    したがって、次のコンテンツは、投稿、アップロード、ストリーミング、共有を禁止します。リストはほんの一部に過ぎず、すべてのケースを含めているわけではありません。
                </u>
            </p>
            <ul style={{ paddingLeft: "15px" }}>
                <li>
                    <p>フィッシングを描写または奨励するコンテンツ&nbsp;</p>
                </li>
                <li>
                    <p>ポンジ(Ponzi)詐欺、*多段階マーケティングまたはピラミッド詐欺を描写または奨励するコンテンツ&nbsp;</p>
                </li>
                <li>
                    <p>高収益を約束する投資詐欺、*勝負操作、またはその他あらゆる種類の詐欺を描写または奨励するコンテンツ</p>
                </li>
                <li>
                    <p>現金支給、「成金になる」広告、多段階販売（多段階構造で実際の製品なしでお金のみ支払い）に関するコンテンツ&nbsp;</p>
                </li>
                <li>
                    <p>早く金持ちになれるとか、奇跡の治療法で癌などの慢性疾患を治せると主張する誇張広告</p>
                </li>
                <li>
                    <p>現金支給またはその他の多段階販売の推進</p>
                </li>
                <li>
                    <p>現金支払を主な目的とするアカウント</p>
                </li>
                <li>
                    <p>&apos;「この方法で明日なら5万ドルを稼ぐことができます」と約束するビデオ</p>
                </li>
            </ul>
            <p className="title-3">▶暴力的で不快なコンテンツ</p>
            <p>
                CELEBeは創造性を重視しますが、ショックを与える暴力的なコンテンツは許容しません。
                不要に衝撃的であるか、不快で、残酷であるか、または他人を悩ませるコンテンツは許容しません。
                極端な暴力や苦痛を奨励、日常化または美化するコンテンツも同様です。
                大衆の安全を脅かす場合、当社はそのアカウントを一時的に停止または使用禁止して、必要に応じて関連する司法当局に報告することがあります。
            </p>
            <p>&nbsp;</p>
            <p>
                <u>
                    したがって、次のコンテンツは、投稿、アップロード、ストリーミング、共有を禁止します。リストはほんの一部に過ぎず、すべてのケースを含めているわけではありません。
                </u>
            </p>
            <p>*人に関する次のようなコンテンツ</p>
            <ul style={{ paddingLeft: "15px" }}>
                <li>
                    <p>ひどい死亡または事故関連&nbsp;</p>
                </li>
                <li>
                    <p>切断、毀損、火に焼かれた死体&nbsp;</p>
                </li>
                <li>
                    <p>開いた傷やケガの部分が核心である血まみれのコンテンツ&nbsp;</p>
                </li>
                <li>
                    <p>現実世界での身体暴力、戦い、拷問</p>
                </li>
            </ul>
            <p>*動物に関する次のようなコンテンツ</p>
            <ul style={{ paddingLeft: "15px" }}>
                <li>
                    <p>動物の屠殺や他の自然史ではない死&nbsp;</p>
                </li>
                <li>
                    <p>切断、毀損、火に焼かれた動物の死体&nbsp;</p>
                </li>
                <li>
                    <p>動物に対する残虐行為及び血まみれのコンテンツ&nbsp;</p>
                </li>
            </ul>
            <p className="title-3">▶自殺、自害、危険行為</p>
            <p>
                CELEBeはユーザーの健康と健康を大切にします。
                自殺、自害、または摂食障害につながる可能性のある活動を描写、奨励、日常化、または美化するコンテンツは許容しません。
                また、ユーザーが重大な怪我や死亡につながる危険な行為に自分が参加した姿を描いたり、他人に参加を勧めるコンテンツも許容しません。
                しかし、ユーザーがこれらのトピックに対する認識を高め、コミュニティの助けを得るために個人的な経験を安全な方法で共有することは支持します。
                また、自害や自殺の考えで苦労しているとか、自殺を真剣に考えている人を知っているユーザーには、すぐに地元の緊急サービスまたは自殺予防ホットラインに申告することをお勧めします。
                CELEBeの介入で自分自身を害する危険にさらされているユーザーを助けることができる場合は、CELEBeが現地の緊急サービスに直接連絡することができます。
            </p>
            <p>&nbsp;</p>
            <p>
                <u>自殺</u>
            </p>
            <p>
                自殺、自殺衝動、または類似した行動を引き起こす可能性のあるコンテンツを含むコンテンツは削除されることがあります。
                自殺の試み描写または自殺につながる可能性の高い行動に参加または参加する意図を示すコンテンツも削除される可能性があります。
                自殺を奨励、日常化、美化するか、自殺の方法を共有、自殺を英雄的または名誉あるものと表現したあらゆる形態のコンテンツを禁止します。
            </p>
            <p>
                <u>
                    したがって、次のコンテンツは、投稿、アップロード、ストリーミング、共有を禁止します。リストはほんの一部に過ぎず、すべてのケースを含めているわけではありません。
                </u>
            </p>
            <ul style={{ paddingLeft: "15px" }}>
                <li>
                    <p>自殺方法を教えるコンテンツ&nbsp;</p>
                </li>
                <li>
                    <p>自殺を描写、奨励、日常化または美化するコンテンツ&nbsp;</p>
                </li>
                <li>
                    <p>自殺ゲーム、自殺挑戦、自殺の約束またはいたずら</p>
                </li>
            </ul>

            <p>
                <u>自害と摂食障害&nbsp;</u>
            </p>
            <p>
                自害行動を日常化、奨励、または誘発しないために、CELEBeはユーザーの投稿意図にかかわらず、そのような行動を描写するイメージを許容しません。
                自傷による身体傷害を引き起こす可能性の高い行為を助長または日常化するコンテンツは削除されることがあります。
                健康に悪影響を及ぼす可能性が高い食習慣を促進するコンテンツも、CELEBe内では許容されません。
            </p>
            <p>
                <u>
                    したがって、次のコンテンツは、投稿、アップロード、ストリーミング、共有を禁止します。リストはほんの一部に過ぎず、すべてのケースを含めているわけではありません。
                </u>
            </p>
            <ul style={{ paddingLeft: "15px" }}>
                <li>
                    <p>自害または摂食障害を描写、奨励、日常化または美化するコンテンツ</p>
                </li>
                <li>
                    <p>自害や摂食障害に参加する方法を教えるコンテンツ&nbsp;</p>
                </li>
                <li>
                    <p>自害または摂食障害に関連するゲーム、挑戦、約束、またはいたずらに関連するコンテンツ</p>
                </li>
                <li>
                    <p>摂食障害などの危険な減量行動を描写、奨励、日常化または美化するコンテンツ</p>
                </li>
            </ul>

            <p>
                <u>危険行為</u>
            </p>
            <p>
                CELEBeは、危険行為またはその他の危険な行動を非専門家が必要とする技術と安全対策を備えていない状態で実行し、ユーザーまたは大衆の重大な怪我や死亡につながる可能性がある場合として定義します。
                アマチュアスタントや危険なチャレンジを含むリスク行為を描写、奨励、日常化、または美化するコンテンツは許容しません。 &nbsp;
            </p>
            <p>
                <u>
                    したがって、次のコンテンツは、投稿、アップロード、ストリーミング、共有を禁止します。リストはほんの一部に過ぎず、すべてのケースを含めているわけではありません。
                </u>
            </p>
            <ul style={{ paddingLeft: "15px" }}>
                <li>
                    <p>危険な道具、自動車、物の不適切な使用を見せるコンテンツ&nbsp;</p>
                </li>
                <li>
                    <p>食用でないか、または深刻な害を及ぼす可能性のある物を食べることを描写または奨励するコンテンツ&nbsp;</p>
                </li>
                <li>
                    <p>怪我につながる危険なゲーム、挑戦、またはスタント</p>
                </li>
            </ul>
            <p className="title-3">▶ハラスメントと嫌がらせ</p>
            <p>
                CELEBeは、嫌がらせの恐れなく個性的な自己表現が可能な包括的なプラットフォームを目指します。
                コミュニティメンバーの侮辱、嫌がらせ、またはハラスメントは許容しません。
                虐待コンテンツや行動は深刻な心理的ストレスを引き起こす可能性があり、そのコンテンツは削除されることがあります。
            </p>
            <p>&nbsp;</p>
            <p>
                <u>虐待行動</u>
            </p>
            <p>
                CELEBeは、脅迫や嘲笑、侮辱、脅威、または傷つけようとする意図の卑下の発言を含む、あらゆる形態の虐待関連コンテンツを禁止します。
                これらの禁止ポリシーは、CELEBeの機能を活用したコンテンツにも適用されます。
                一般大衆に重要な問題に関する表現を許容するために、認定に関する批判的なコメントは許容されます。
                しかし、認定に対抗する重大な虐待行為は禁止します。 &nbsp;
            </p>
            <p>
                <u>
                    したがって、次のコンテンツは、投稿、アップロード、ストリーミング、共有を禁止します。リストはほんの一部に過ぎず、すべてのケースを含めているわけではありません。
                </u>
            </p>
            <ul style={{ paddingLeft: "15px" }}>
                <li>
                    <p>知能、外見、性格、衛生状態に基づいて他人を侮辱または解放するコンテンツ&nbsp;</p>
                </li>
                <li>
                    <p>複数の人が協議して誰かを嫌がらせすることをお勧めするコンテンツ&nbsp;</p>
                </li>
                <li>
                    <p>暴力的な悲劇の犠牲者を卑下するコンテンツ&nbsp;</p>
                </li>
                <li>
                    <p>CELEBeインタラクティブ機能を活用した他人を卑下するコンテンツ&nbsp;</p>
                </li>
                <li>
                    <p>サイバーストーキングやトローリングなど、意図的な被害や脅迫を描写するコンテンツ&nbsp;</p>
                </li>
                <li>
                    <p>特定人や公人の死または病気など深刻な被害を望むコンテンツ</p>
                </li>
            </ul>
            <p>&nbsp;</p>
            <p>
                <br />

                <u>セクハラ</u>
            </p>
            <p>
                セクハラは、他人に向かって望ましくないか、または不適切な性的行為を意味します。
                CELEBeは、ユーザーの意図にかかわらず、コメントやアクセスを含む他人を性的に嫌がらせするコンテンツを許容しません。
                これは、そのような行動が標的となった対象に激しいストレスを与える可能性があるためです。 &nbsp;
            </p>
            <p>
                <u>
                    したがって、次のコンテンツは、投稿、アップロード、ストリーミング、共有を禁止します。リストはほんの一部に過ぎず、すべてのケースを含めているわけではありません。
                </u>
            </p>
            <ul style={{ paddingLeft: "15px" }}>
                <li>
                    <p>不要な性的接触を試みるコンテンツ&nbsp;</p>
                </li>
                <li>
                    <p>言葉やテキスト（絵文字を含む）とアプリ内の機能を活用して他のユーザーと性的行為をするようなコンテンツ&nbsp;</p>
                </li>
                <li>
                    <p>他人の性的行為を卑下するコンテンツ&nbsp;</p>
                </li>
                <li>
                    <p>他人のイメージを変えたりして性的暗示や性的行為に加わることを描写または暗示するコンテンツ&nbsp;</p>
                </li>
                <li>
                    <p>
                        デジタルコンテンツ、性的過去、過去のセックスパートナーの名前を公開するという脅迫を含めて、誰かの個人的な性生活を公開するか、またはそのようにすると脅かすコンテンツ&nbsp;
                    </p>
                </li>
                <li>
                    <p>誰かの性的指向を同意なしに、または当事者が知らないように露出するか、またはそのようにすると脅すコンテンツ</p>
                </li>
            </ul>

            <p>
                <u>ハッキング、ドキシング(Doxxing)、及び脅威 </u>
                &nbsp;
            </p>
            <p>
                타인을 희롱 또는 협박하려는 의도로 해킹 또는 신상 털기를 하겠다고 위협하는 것은 심각한 감정적 피해 및 기타 오프라인 상의 피해를 끼칠
                수 있습니다.&nbsp;
            </p>
            <p>
                他人を嫌がらせたり、脅迫したりする意図でハッキングやドキシングをすると脅かすことは、深刻な感情的損害やその他のオフライン上のダメージを与える可能性があります。
                CELEBeは、ドキシング(Doxxing)を個人情報または個人識別情報（PII）を悪意のある目的で収集及び開示する行為として定義します。
                これらのオンライン上の行動は一種の虐待と見なされ、CELEBE内で禁止されています。
            </p>
            <p>
                <u>
                    したがって、次のコンテンツは、投稿、アップロード、ストリーミング、共有を禁止します。リストはほんの一部に過ぎず、すべてのケースを含めているわけではありません。
                </u>
            </p>
            <ul style={{ paddingLeft: "15px" }}>
                <li>
                    <p>
                        自宅の住所、公開されていない電子メール、非公開の電話番号、銀行履歴書、社会保障番号またはパスポート番号を含む個人情報または個人識別情報（PII）を開示すると脅すコンテンツ&nbsp;
                    </p>
                </li>
                <li>
                    <p>他人のアカウントをハッキングまたは奪い取ると脅すコンテンツ</p>
                </li>
                <li>
                    <p>
                        他人に、特定の個人のアカウント、個人情報、または個人識別情報（PII）をハッキングまたは開示するように促すまたは助長するコンテンツ&nbsp;
                    </p>
                </li>
                <li>
                    <p>
                        他人が誤用、トローリング、または嫌がらせができるように、誰かのアカウント、個人情報、または個人の識別情報を公開するコンテンツ
                    </p>
                </li>
            </ul>
            <p className="title-3">▶大人の全裸露出と性的活動</p>
            <p>
                CELEBeは安全なプラットフォームになりたいと考えています。 全裸の露出、ポルノ、性的に露骨なコンテンツはCELEBe内で禁止されています。
                同意のない性行為を描いたり支持したり、同意しない性的イメージを共有したり、成人の性的搾取を描いたり支持したりするコンテンツも許容しません。
            </p>
            <p>&nbsp;</p>
            <p>
                <u>性的搾取</u>
            </p>
            <p>
                性的搾取とは、性的目的で本人の信頼度、地位または他人の弱みを悪用または悪用しようとすることと定義して、他人の性的搾取から経済的、社会的、または政治的利益を見る行為も含めます。
            </p>
            <p>
                <u>
                    したがって、次のコンテンツは、投稿、アップロード、ストリーミング、共有を禁止します。リストはほんの一部に過ぎず、すべてのケースを含めているわけではありません。
                </u>
            </p>
            <ul style={{ paddingLeft: "15px" }}>
                <li>
                    <p>レイプと性的暴力を含む同意のない性行為または接触を描写、要求/提案、助長、日常化または美化するコンテンツ&nbsp;</p>
                </li>
                <li>
                    <p>
                        同意なしに撮影、制作、または共有された性的イメージを含む、同意されていない性的イメージを共有することを描写、要求/提案、奨励、日常化、または美化するコンテンツ
                    </p>
                </li>
                <li>
                    <p>性的暴力を描写、奨励、日常化または美化するコンテンツ&nbsp;</p>
                </li>
                <li>
                    <p>
                        性的パートナー、性的チャットまたはイメージ、性的サービス、プレミアム性的コンテンツ、またはセックスカム（他人のセックスをリアルタイムで見せるサービス）の提案及び要求を含む性的要求/提案を描写、奨励または美化するコンテンツ
                    </p>
                </li>
            </ul>
            <p>&nbsp;</p>
            <p>&nbsp;</p>
            <p>
                <u>成人が関与する全裸露出と性的行為&nbsp;</u>
            </p>
            <p>
                全裸暴露及び性的行為には、乳房、性器、肛門または臀部を公然とさらしたり、性行為を模倣したり、暗示したり、見せたりする行動が含まれます。
                CELEBeは、デジタル方式で制作または操作されたコンテンツを含む全裸露出や性的活動の描写を許容しません。
                特定の地域で不快感を与えたり、文化的に不適切と見なされる可能性のあるコンテンツ、すべての年齢層に適していない可能性もあるコンテンツに留意しています。
            </p>
            <p>
                <u>
                    したがって、次のコンテンツは、投稿、アップロード、ストリーミング、共有を禁止します。リストはほんの一部に過ぎず、すべてのケースを含めているわけではありません。
                </u>
            </p>
            <ul style={{ paddingLeft: "15px" }}>
                <li>
                    <p>挿入性交と非挿入性交、経口性交、性的キスなどの露骨な性的または暗黙的に性的行為を描写するコンテンツ&nbsp;</p>
                </li>
                <li>
                    <p>性的興奮や性的刺激を描写するコンテンツ&nbsp;</p>
                </li>
                <li>
                    <p>性的フェチを描いたコンテンツ&nbsp;</p>
                </li>
                <li>
                    <p>露出した人間の生殖器、女性の乳首または乳輪、恥骨または臀部を描くコンテンツ&nbsp;</p>
                </li>
                <li>
                    <p>性的満足のために性的に露骨な言語を含むコンテンツ</p>
                </li>
            </ul>

            <p className="title-3">▶未成年者の安全</p>
            <p>
                CELEBeでは未成年者を虐待、搾取、または危険にさらすか、または害する活動を許容しません。
                アニメーションやデジタル方式で制作または操作されたメディアを含む、未成年者への虐待、搾取、または全裸露出を描いたすべてのコンテンツは、当社のプラットフォーム上でポリシー違反とみなされ、発見されるとすぐに削除されます。
                必要に応じて、当該コンテンツは、国立行方不明・虐待児童防止センター（NCMEC）及び/又はその他の関連司法当局に報告することがあります。
                CELEBeは未成年者を18歳未満と定義します。
                ユーザーは、サービス約款に記載されているCELEBeの最低年齢要件（13歳未満）を満たす必要があります。
                年齢要件を満たしていないアカウントの所有者が確認されると、そのアカウントは削除されます。
            </p>
            <p>&nbsp;</p>
            <p>
                <u>未成年者の性的搾取&nbsp;</u>
            </p>
            <p>
                未成年者の性的搾取は、性的目的で本人の信頼度または地位を悪用することと定義されますが、未成年者の搾取から経済的、社会的、性的または政治的利益を見ることも含まれます。
                児童性的虐待資料（CSAM）とは、略奪的な成人及び同年生または未成年者が自らが描いた性的に露骨な全裸露出または行為を視覚的に描写したものと定義されます。
                CELEBeは、成人と未成年者、または年齢差の多い未成年者との性的交流と接近に関するコンテンツまたはアカウントに対して対処を取ります。
            </p>
            <p>
                <u>
                    したがって、次のコンテンツは、投稿、アップロード、ストリーミング、共有を禁止します。リストはほんの一部に過ぎず、すべてのケースを含めているわけではありません。
                </u>
            </p>
            <ul style={{ paddingLeft: "15px" }}>
                <li>
                    <p>
                        CSAMを共有、再共有、取引または販売する提案、及びCSAMを入手または配布するためにプラットフォーム外にユーザーを誘い出すコンテンツ&nbsp;
                    </p>
                </li>
                <li>
                    <p>
                        未成年者と性的に関連するコンテンツ、または他の方法で未成年者を性的対象化（例えば、デュエットなどの製品機能を介して）するコンテンツ&nbsp;
                    </p>
                </li>
                <li>
                    <p>
                        未成年者の全裸露出、性的対象化、または未成年者との性的行為を含む性的虐待イメージを描写、要求/提案、美化または推奨するコンテンツ&nbsp;
                    </p>
                </li>
                <li>
                    <p>小児性愛または未成年者の性的暴力を描写、奨励、日常化または美化するコンテンツ&nbsp;</p>
                </li>
                <li>
                    <p>
                        第三者が性的暴力または告白の内容を再現または共有することによって、未成年者である虐待被害者が再び被害を受けたり悪用される可能性があるコンテンツ&nbsp;
                    </p>
                </li>
            </ul>
            <p>&nbsp;</p>
            <p>&nbsp;</p>
            <p>
                <u>グルーミング行為</u>
            </p>
            <p>
                グルーミング行為とは、成人が未成年者を後にまたは継続的に性的接触、性的虐待、性売買またはその他の搾取をするために未成年者の信頼を得ようと、彼らと情緒的な関係を構築する行為です。
                このような行為には、諂い、CELEBe内外での接触要求、個人情報の要求、未成年者性的虐待資料の要求/提案、性的要求/提案またはコメント及びギフト提供などがあります。
            </p>
            <p>
                <u>
                    したがって、次のコンテンツは、投稿、アップロード、ストリーミング、共有を禁止します。リストはほんの一部に過ぎず、すべてのケースを含めているわけではありません。
                </u>
            </p>
            <ul style={{ paddingLeft: "15px" }}>
                <li>
                    <p>グルーミングをするための接近</p>
                </li>
                <li>
                    <p>グルーミング行為を描写、奨励、日常化または美化するコンテンツ&nbsp;</p>
                </li>
                <li>
                    <p>現実の世界で、未成年者と成人間、または年齢差の多い未成年者との性的接触を要求/提案するコンテンツ&nbsp;</p>
                </li>
                <li>
                    <p>未成年者に全裸露出イメージを見せるか、または提案するコンテンツ&nbsp;</p>
                </li>
                <li>
                    <p>脅迫または他の強制的手段によってヌードイメージまたは性的接触を要求/提案するすべてのコンテンツ&nbsp;</p>
                </li>
            </ul>

            <p>
                <u>未成年者が関与する全裸露出と性的活動&nbsp;</u>
            </p>
            <p>
                未成年者が関与する全裸露出及び性的活動は、乳房、性器、肛門、または臀部を公然とさらしたり、未成年者が関与した性行為を模倣したり、暗示したり、見せたりするコンテンツです。
                CELEBeは、デジタル方式で制作または操作されたコンテンツを含む全裸露出または性的活動を描写するコンテンツを許容しません。 &nbsp;
            </p>
            <p>
                <u>
                    したがって、次のコンテンツは、投稿、アップロード、ストリーミング、共有を禁止します。リストはほんの一部に過ぎず、すべてのケースを含めているわけではありません。
                </u>
            </p>
            <ul style={{ paddingLeft: "15px" }}>
                <li>
                    <p>挿入または非挿入性交、口腔性交、煽情的キスを含む未成年者の性的活動を描写または暗示するコンテンツ&nbsp;</p>
                </li>
                <li>
                    <p>未成年者が関与する性的興奮または性的刺激を描写するコンテンツ&nbsp;</p>
                </li>
                <li>
                    <p>未成年者が関与した性的フェチを描いたコンテンツ&nbsp;</p>
                </li>
                <li>
                    <p>未成年者の性器、乳首または乳輪、恥骨または臀部を描写するコンテンツ&nbsp;</p>
                </li>
                <li>
                    <p>性的に露骨な言語で未成年者を描写または説明するコンテンツ&nbsp;</p>
                </li>
                <li>
                    <p>性的に露骨な歌の歌詞を含む未成年者の説明コンテンツ&nbsp;</p>
                </li>
                <li>
                    <p>
                        トワーキング(Twerking)、胸の揺れ、骨盤の押し込み、または自分または他人の鼠径部や乳房の触れなど、性的に露骨な未成年者のダンスを含むコンテンツ&nbsp;
                    </p>
                </li>
                <li>
                    <p>未成年者が服を脱ぐことを描写するコンテンツ&nbsp;</p>
                </li>
                <li>
                    <p>ある場所で状況上不適切に未成年者が服をほとんど着ていないことを描写するコンテンツ&nbsp;</p>
                </li>
                <li>
                    <p>
                        未成年者の全裸状態または性的活動を隠すか、または暗示するための性的なコメント、絵文字、テキスト、またはその他のグラフィックコンテンツ
                    </p>
                </li>
            </ul>
            <p>&nbsp;</p>
            <p>&nbsp;</p>
            <p>
                <u>未成年者による有害活動</u>
            </p>
            <p>
                未成年者の有害活動には、未成年者に禁止された物質の所持と消費、合法的な薬物の誤用、違法行為の加担、未成年者のウェルビーイングに脅威となる活動、チャレンジ及び挑戦参加などがあります。
                そのようなコンテンツは削除されることがあります。
            </p>
            <p>
                <u>
                    したがって、次のコンテンツは、投稿、アップロード、ストリーミング、共有を禁止します。リストはほんの一部に過ぎず、すべてのケースを含めているわけではありません。
                </u>
            </p>
            <ul style={{ paddingLeft: "15px" }}>
                <li>
                    <p>未成年者のアルコール飲料、タバコ、麻薬の所持または消費を暗示、描写、模倣、または奨励するコンテンツ&nbsp;</p>
                </li>
                <li>
                    <p>未成年者を対象にアルコール、タバコ、または規制薬物の購入、販売、または取引方法を教えるコンテンツ&nbsp;</p>
                </li>
                <li>
                    <p>物理的チャレンジ、挑戦、スタントを含む青少年のウェルビーイングを脅かす活動を描写または奨励するコンテンツ</p>
                </li>
            </ul>

            <p>
                <u>未成年者に対する身体的及び心理的被害&nbsp;</u>
            </p>
            <p>
                未成年者に身体的または心理的損害を与える可能性のある行動には、身体的な虐待、放置、危険に露出、心理的な卑下が含まれます。
                そのようなコンテンツは削除されることがあります。
            </p>
            <p>
                <u>
                    したがって、次のコンテンツは、投稿、アップロード、ストリーミング、共有を禁止します。リストはほんの一部に過ぎず、すべてのケースを含めているわけではありません。
                </u>
            </p>
            <ul style={{ paddingLeft: "15px" }}>
                <li>
                    <p>未成年者の身体的虐待、放置、危険に露出、心理的な卑下を描写または奨励するコンテンツ</p>
                </li>
                <li>
                    <p>模倣の危険性に対する警告なしに極端な状況で生き残る方法を描写または奨励するコンテンツ</p>
                </li>
            </ul>

            <p>
                <u>子供に対する犯罪</u>
            </p>
            <p>
                CELEBeは子供に対する犯罪で有罪判決を受けたユーザーが、当社のプラットフォーム上でアカウントを開設することを許容しません。
                このような犯罪には、性的暴力、醜行、殺人、身体的な虐待または放置、誘拐、親による海外への誘拐、密売、売春のための未成年者の搾取、未成年者へのオンライン上での性的虐待、旅行及び観光に関連する未成年者の性的搾取、児童に対する性的虐待資料（CSAM）の入手と流通の試み、CSAMの制作、所持及び流通が含まれます。
                これらのユーザーのアカウントは見つかれば直ちに削除されます。
                ユーザー情報で、アカウントの所有者が小児性愛者または未成年者の性犯罪者であることを自ら明らかにした場合は、そのような情報を事実と見なして、アカウントを削除します。
                &nbsp;
            </p>
            <p className="title-3">▶真実性と真正性</p>
            <p>
                CELEBeは、プラットフォームの真実性とユーザーの真正性を損なう可能性のある活動を許容しません。スパムまたは偽のインタラクション、詐欺、虚偽の情報を使用してダメージを与えるか、知的財産権に違反したコンテンツやアカウントを削除することができます。
            </p>
            <p>&nbsp;</p>
            <p>
                <u>虚偽情報</u>
            </p>
            <p>
                虚位情報とは、不正確または誤った情報として定義されます。当社では、CELEBeコミュニティにとって重要なトピックについてお互いを尊重する会話をお勧めしますし、意図とは関係ないユーザー個人、コミュニティ、または一般大衆に害になる虚位情報は許容しません。
            </p>
            <p>
                <u>
                    したがって、次のコンテンツは、投稿、アップロード、ストリーミング、共有を禁止します。リストはほんの一部に過ぎず、すべてのケースを含めているわけではありません。
                </u>
            </p>
            <ul style={{ paddingLeft: "15px" }}>
                <li>
                    <p>憎悪や偏見を助長する虚偽情報&nbsp;</p>
                </li>
                <li>
                    <p>混乱を引き起こす緊急事態に関する虚偽情報&nbsp;</p>
                </li>
                <li>
                    <p>身体健康に害を及ぼす可能性のある誤った医療情報</p>
                </li>
                <li>
                    <p>選挙または市民参加の過程に対してコミュニティメンバーを誤解させるコンテンツ&nbsp;</p>
                </li>
                <li>
                    <p>
                        特定の保護対象に攻撃または暴力的な行動を促すか、または過去に発生した暴力的または悲劇的な出来事を否定する陰謀論コンテンツ&nbsp;
                    </p>
                </li>
                <li>
                    <p>
                        事件関連の真実を歪めることで、ユーザーを誤解させ、ビデオの対象やその他の人物または社会全体に害を及ぼすデジタル偽造（メディア合成または操作）コンテンツ
                    </p>
                </li>
            </ul>
            <p>
                アカウントの正体、位置、目的に関して、個人と当社のコミュニティまたは公衆に間違った情報を提供しながら影響力を行使して、世論を操作するためにアカウントを生成するなど、真実ではない活動には組織的に関与しないでください。&nbsp;
            </p>
            <p>&nbsp;</p>
            <p>
                <u>スパム</u>
            </p>
            <p>
                CELEBeは、過度に頻繁に投稿されるか、反復されるか、または明確な対象がなく、次の内容を一つ以上含むコンテンツを許容しません。
                安全で肯定的なコミュニティを維持するために、アカウントから24時間アップロードできるビデオフィード数を制限しています。
                ビデオフィードをアップロードしようとした時、「1日のアップロード限度到達」メッセージが表示されたら、約24時間後にもう一度アップロードしてください。
            </p>
            <p>&nbsp;</p>
            <p>
                <u>
                    したがって、次のコンテンツは、投稿、アップロード、ストリーミング、共有を禁止します。リストはほんの一部に過ぎず、すべてのケースを含めているわけではありません。
                </u>
            </p>
            <ul style={{ paddingLeft: "15px" }}>
                <li>
                    <p>視聴者に何かを見せると約束するが、見せずに外部サイトに誘導するコンテンツ</p>
                </li>
                <li>
                    <p>同じコンテンツを1つ以上のアカウントに繰り返して投稿する行為</p>
                </li>
                <li>
                    <p>視聴者に迅速な収益化を約束しながらCELEBe外部サイトへのクリック、閲覧、またはトラフィックを誘導するコンテンツ</p>
                </li>
                <li>
                    <p>有害なソフトウェアを配布するか、個人情報を収集するサイトまたは悪影響を及ぼす他のサイトに視聴者を誘惑するコンテンツ</p>
                </li>
                <li>
                    <p>品質や視聴者の経験を考慮せずにコンピュータで自動生成されたコンテンツ</p>
                </li>
                <li>
                    <p>
                        ソフトウェアをインストール、アプリをダウンロード、他の行為をしている視聴者に現金、製品、ソフトウェア、またはゲームの特典を無料で提供すると約束するコンテンツ
                    </p>
                </li>
            </ul>
            <p>&nbsp;</p>
            <p>&nbsp;</p>
            <p>
                <u>インセンティブスパム</u>
            </p>
            <p>
                CELEBeは、ビュー数、「いいね」数、コメント数などの参加度の測定項目を販売するコンテンツを許容しません。
                これらのタイプのスパムには、購読者数、ビュー数、またはその他の測定項目を増やすことが唯一な目的であるコンテンツも含まれます。たとえば、自分のアカウントをフォローする条件のみで、他のクリエーターのアカウントをフォローすることを提案するコンテンツがこれに該当します。
                プラットフォーム上で人気を実際に膨らませるためのすべてのコンテンツと行動を含みます。
                CELEBeは、インタラクション数値を増やすためのすべてのプラットフォーム操作の試みを禁止します。 &nbsp;
            </p>
            <p>
                <u>
                    したがって、次のコンテンツは、投稿、アップロード、ストリーミング、共有を禁止します。リストはほんの一部に過ぎず、すべてのケースを含めているわけではありません。
                </u>
            </p>
            <ul style={{ paddingLeft: "15px" }}>
                <li>
                    <p>視聴者のフォローを誘導する目的のビデオ</p>
                </li>
                <li>
                    <p>&apos;お互いフォローし合う提案のビデオ</p>
                </li>
                <li>
                    <p>他のコンテンツなしで10万番目のフォロワーにアカウントを引き渡すと提案するビデオ</p>
                </li>
                <li>
                    <p>ビュー数、「いいね」、フォロワー、共有、コメントを人為的に増やす方法を共有&nbsp;</p>
                </li>
                <li>
                    <p>ビュー数、「いいね」、フォロワー、共有、コメントのマーケティングや試み&nbsp;</p>
                </li>
                <li>
                    <p>人為的なトラフィックの生成サービスの宣伝</p>
                </li>
                <li>
                    <p>商業的なスパム配布のための多数の仮名または仮CELEBeアカウントの運営</p>
                </li>
            </ul>
            <p>&nbsp;</p>
            <p>&nbsp;</p>
            <p>
                <u>コメントスパム</u>
            </p>
            <p>
                CELEBeは、視聴者の個人情報を収集するか、誤った情報で視聴者をCELEBeの外部サイトへ誘引するか、上記の禁止行動を行うことを唯一の目的で作成されたコメントは許容しません。
                これらのタイプのスパムには、購読者数、ビュー数、またはその他の測定項目を増やすことが唯一の目的であるコンテンツも含まれます。たとえば、自分のアカウントを購読する条件のみで他のクリエーターのアカウントを購読することを提案するコンテンツがこれに該当します。
            </p>
            <p>
                <u>
                    したがって、次のコンテンツは、投稿、アップロード、ストリーミング、共有を禁止します。リストはほんの一部に過ぎず、すべてのケースを含めているわけではありません。
                </u>
            </p>
            <ul style={{ paddingLeft: "15px" }}>
                <li>
                    <p>多段階の販売を宣伝するアンケートまたは贈呈品に関するコメント</p>
                </li>
                <li>
                    <p>「クリック当りの支払い」の推薦リンクが含まれたコメント</p>
                </li>
                <li>
                    <p>次のような種類のビデオコンテンツの全体を提供すると誤って主張するコメント</p>
                    <ul style={{ paddingLeft: "15px" }}>
                        <li>
                            <p>映画</p>
                        </li>
                        <li>
                            <p>テレビ番組</p>
                        </li>
                        <li>
                            <p>コンサート</p>
                        </li>
                    </ul>
                </li>
                <li>
                    <p>
                        コメントに有害なソフトウェアやフィッシングサイトへのリンクを投稿（例：「ゲームマネーをゲットしてください！ - [xyz phishing
                        site].com」）
                    </p>
                </li>
                <li>
                    <p>偽造品店へのリンクを含むコメント</p>
                </li>
                <li>
                    <p>投稿したビデオの内容に関連しないコメント（例：「マイアカウント/ビデオを確認してください！」）</p>
                </li>
                <li>
                    <p>自分のアカウントへのリンクを含む同じコメントを繰り返して投稿</p>
                </li>
                <li>
                    <p>内容が同じか、明確なターゲットがないコメントを繰り返して投稿</p>
                </li>
            </ul>
            <p>&nbsp;</p>
            <p>&nbsp;</p>
            <p>
                <u>混乱を引き起こすサムネイル</u>
            </p>
            <p>CELEBeはサムネイル、説明欄を利用してユーザーがコンテンツの内容を他のコンテンツと誤解するように欺くコンテンツを許容しません。&nbsp;</p>
            <p>
                <u>
                    したがって、次のコンテンツは、投稿、アップロード、ストリーミング、共有を禁止します。リストはほんの一部に過ぎず、すべてのケースを含めているわけではありません。
                </u>
            </p>
            <ul style={{ paddingLeft: "15px" }}>
                <li>
                    <p>コンテンツに関係のない有名人の写真をサムネイルに使用</p>
                </li>
                <li>
                    <p>サムネイル、説明を利用して、ユーザーがコンテンツの内容を他のコンテンツと誤解させるコンテンツ</p>
                </li>
            </ul>

            <p>
                <u>詐称</u>
            </p>
            <p>
                CELEBeは、他人や団体に詐称するアカウントを許容しません。
                詐欺に関連する報告を受けた後、詐欺が事実であると確認された場合は、そのユーザーにプロフィール修正を依頼するか、ユーザーのアカウントを一時的に停止または使用を禁止します。
                パロディ、論評、ファンアカウントの場合、ユーザーがプロフィールとユーザー名にファン、論評、またはパロディであることを明らかにして、アカウントの主題であるターゲットに関連付けがないことを明らかにした場合は、これを許容します。
            </p>
            <p>
                <u>したがって、以下の行為は禁止です。</u>
            </p>
            <ul style={{ paddingLeft: "15px" }}>
                <li>
                    <p>他人を欺くために、他人の名前、履歴情報、またはプロフィール写真を使用して他人を詐称する</p>
                </li>
            </ul>
            <p className="title-3">▶個人情報侵害</p>
            <p>個人情報侵害の申告手続きの詳細説明と、個人情報侵害の申告を評価時の考慮要因について取り上げます。</p>
            <p>個人を一意に識別できるかどうかを評価する際に考慮すべき要因は次のとおりです。</p>
            <ul style={{ paddingLeft: "15px" }}>
                <li>
                    <p>イメージまたは音声</p>
                </li>
                <li>
                    <p>名前</p>
                </li>
                <li>
                    <p>金融情報</p>
                </li>
                <li>
                    <p>連絡先情報</p>
                </li>
                <li>
                    <p>その他個人の識別情報</p>
                </li>
            </ul>
            <p>&nbsp;</p>
            <p>
                個人またはアカウントの名義を盗む目的のコンテンツは、CELEBeでは許容しません。また、CELEBeは商標権者の権利を行使します。アカウントまたはアカウントのコンテンツによって広告中の商品やサービスの出所に対して混乱の可能性がある場合、これは許容されません。
                以下の内容のいずれかが該当する場合は、コンテンツをCELEBeに投稿しないでください。 &nbsp;
            </p>
            <ul style={{ paddingLeft: "15px" }}>
                <li>
                    <p>
                        •
                        アカウント名義の盗用：他人のアカウントのように見えるように、他人のアカウントのプロフィール、背景、または全体的なデザイン及び雰囲気を模倣したアカウントです。他のアカウントを模倣する意図が明確である場合は、100％同じでなくてもアカウント名の盗用に該当します。
                    </p>
                </li>
                <li>
                    <p>個人名義の盗用：他人が投稿したように見えるように意図したコンテンツです。</p>
                </li>
            </ul>
            <p>&nbsp;</p>
            <p>
                <u>
                    したがって、次のコンテンツは、投稿、アップロード、ストリーミング、共有を禁止します。リストはほんの一部に過ぎず、すべてのケースを含めているわけではありません。
                </u>
            </p>
            <ul style={{ paddingLeft: "15px" }}>
                <li>
                    <p>
                        他人のアカウント名にスペースを1つ挿入するか、英字Oの代わりに数字0を使用する程度の微妙な違いがあるだけで、他人のアカウントと名前及びイメージが同じアカウント
                    </p>
                </li>
                <li>
                    <p>他人の本名、ユーザー名、イメージ、ブランド、ロゴまたはその他の個人情報を使用してその人物の行為をするコンテンツ&nbsp;</p>
                </li>
                <li>
                    <p>他のアカウントと正確に一致するアカウントの説明</p>
                </li>
                <li>
                    <p>
                        特定人物の名前とイメージを使用してアカウントを設定した後、その人物がこのアカウントにコンテンツを投稿することのように見せかける
                    </p>
                </li>
                <li>
                    <p>特定人物の名前とイメージを使用してアカウントを設定した後、その人物に偽装して他のアカウントにコメントを付ける</p>
                </li>
                <li>
                    <p>
                        「ファンアカウント」と主張していますが、実際には他人のアカウントに偽装し、そのアカウントのコンテンツを再アップロードするアカウント
                    </p>
                </li>
                <li>
                    <p>既存のニュースアカウントを詐称するアカウント</p>
                </li>
            </ul>
            <p>&nbsp;</p>
            <p>
                <u>プライバシー、個人情報及び個人識別情報（PII）&nbsp;</u>
            </p>
            <p>
                CELEBeは、個人情報または個人識別情報（例：社会保障情報など）を公開するコンテンツを許容しません。
                個人情報または個人識別情報（PII）を表すコンテンツは削除されることがあります。
            </p>
            <p>
                <u>
                    したがって、次のコンテンツは、投稿、アップロード、ストリーミング、共有を禁止します。リストはほんの一部に過ぎず、すべてのケースを含めているわけではありません。
                </u>
            </p>
            <ul style={{ paddingLeft: "15px" }}>
                <li>
                    <p>個人情報または個人識別情報（PII）を含むコンテンツ</p>
                </li>
            </ul>
            <p className="title-3">▶知的財産権の侵害 </p>
            <p>
                CELEBeは、すべてのユーザーにオリジナルのコンテンツを作成して共有することをお勧めします。
                他人の知的財産権を侵害するコンテンツは、当社のプラットフォームでは禁止されており、発見直後に関連コンテンツは削除されます。 &nbsp;
            </p>
            <p>&nbsp;</p>
            <p>
                <u>
                    したがって、次のコンテンツは、投稿、アップロード、ストリーミング、共有を禁止します。リストはほんの一部に過ぎず、すべてのケースを含めているわけではありません。
                </u>
            </p>
            <ul style={{ paddingLeft: "15px" }}>
                <li>
                    <p>テレビ番組、映画、オンラインビデオなどの視聴覚作品</p>
                </li>
                <li>
                    <p>音源と音楽作品</p>
                </li>
                <li>
                    <p>講演、記事、書籍、音楽作品などの著作作品</p>
                </li>
                <li>
                    <p>絵、ポスター、広告などの視覚作品</p>
                </li>
                <li>
                    <p>ビデオゲームとコンピュータソフトウェア</p>
                </li>
                <li>
                    <p>演劇、ミュージカルなどの劇作品</p>
                </li>
                <li>
                    <p>他人の著作権、商標権、その他の知的財産権に違反または侵害するコンテンツ</p>
                </li>
            </ul>
            <p className="title-3">▶プラットフォームセキュリティ</p>
            <p>当社のポリシーは、CELEBeサービスのセキュリティと信頼性を妨げる可能性のある以下のすべての活動を禁止しています。&nbsp;</p>
            <p>
                <u>したがって、以下の行為は禁止です。</u>
            </p>
            <ul style={{ paddingLeft: "15px" }}>
                <li>
                    <p>
                        CELEBeウェブサイト、アプリ、または関連ネットワークをハッキングしたり、ユーザーアクセスを制限したりするための措置を回避しないでください。&nbsp;
                    </p>
                </li>
                <li>
                    <p>
                        ウイルス、トロイの木馬（マルウェア）、ワーム、論理爆弾、その他の悪意や有害なコンテンツを含むファイルを配布しないでください。&nbsp;
                    </p>
                </li>
                <li>
                    <p>
                        ファイル、表、または文書を含むCELEBeに基づく派生製品を変更、変容、翻訳、逆設計、解体、逆コンパイルすることを避けてください。&nbsp;
                    </p>
                </li>
                <li>
                    <p>CELEBeに内在されたソースコード、アルゴリズム、スキーム、またはテクニックを再生しようとしないでください。</p>
                </li>
                <li>
                    <p>自動化されたスクリプトを使用してCELEBeから情報を収集しないでください。</p>
                </li>
            </ul>

            <p>&nbsp;</p>
            <p>
                活発なグローバルプラットフォームCELEBeの一員として、CELEBeがすべてのユーザーより喜ばれるスペースになるようにご協力いただき、ありがとうございます。
            </p>
            <p>
                CELEBeガイドラインに違反していると思われるコンテンツやアカウントが見つかった場合は、適切な措置を取られるようにCELEBeまでお知らせください。&nbsp;
            </p>
            <p>&nbsp;</p>
        </div>
    )
}
