import React, { useEffect, useState } from "react"
import AdvertisingInformationKo from "./AdvertisingInformationKo"
import AdvertisingInformationEn from "./AdvertisingInformationEn"
import AdvertisingInformationJa from "./AdvertisingInformationJa"
import "./styles.scss"

export default function AdvertisingInformation() {
    const [lang, setLang] = useState("ko")
    const [theme, setTheme] = useState("dark")

    useEffect(() => {
        const uri = new URL(window.location)
        const lang = uri.searchParams.get("lang")
        if (lang) setLang(lang)
        const theme = uri.searchParams.get("theme")
        if (theme && theme === "dark") {
            setTheme("dark")
        } else {
            setTheme("white")
        }
    }, [])

    return (
        <div className="app-container">
            {lang === "ko" ? (
                <AdvertisingInformationKo theme={theme} />
            ) : lang === "ja" ? (
                <AdvertisingInformationJa theme={theme} />
            ) : (
                <AdvertisingInformationEn theme={theme} />
            )}
        </div>
    )
}
