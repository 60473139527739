import React from "react"

import JaTerm240725 from "./JaTerm240725"

export default function TermsJa({ theme, date }) {
    const terms = () => {
        switch (date) {
            case "240725":
                return <JaTerm240725 />
            default:
                return <JaTerm240725 />
        }
    }
    return (
        <div className={`terms-ko ${theme === "dark" ? "dark" : ""}`}>
            <h1>利用規約</h1>
            {terms()}
        </div>
    )
}
