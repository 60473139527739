import React, { useEffect, useState } from "react"
import "./styles.scss"
import PolicyKo from "./PolicyKo"
import PolicyEn from "./PolicyEn"
import PolicyTh from "./PolicyTh"
import PolicyJa from "./PolicyJa"
import useGetPolicyDate from "./hooks/useGetPolicyDate"

export default function PrivacyPolicy() {
    const [lang, setLang] = useState("ko")
    const [theme, setTheme] = useState("dark")
    const [selectedDate, setSelectedDate] = useState("")
    const { optionDates, latestDate } = useGetPolicyDate({ lang })

    useEffect(() => {
        setSelectedDate(latestDate)
    }, [latestDate])

    useEffect(() => {
        const uri = new URL(window.location)
        const lang = uri.searchParams.get("lang")
        if (lang) setLang(lang)
        const theme = uri.searchParams.get("theme")
        if (theme && theme === "dark") {
            setTheme("dark")
        } else {
            setTheme("white")
        }
    }, [])

    const handleChangeSelect = (e) => {
        setSelectedDate(e.target.value)
    }

    return (
        <div className="policy-container">
            {lang !== "th" && (
                <>
                    <div className={`date-box ${theme === "dark" ? "dark" : ""}`}>
                        <span>Date: </span>
                        <select name="optionDate" onChange={handleChangeSelect} value={selectedDate}>
                            {optionDates.map((date) => (
                                <option key={date} value={date}>
                                    {date}
                                </option>
                            ))}
                        </select>
                    </div>
                </>
            )}

            {lang === "ko" ? (
                <PolicyKo theme={theme} date={selectedDate} />
            ) : lang === "th" ? (
                <PolicyTh theme={theme} date={selectedDate} />
            ) : lang === "ja" ? (
                <PolicyJa theme={theme} date={selectedDate} />
            ) : (
                <PolicyEn theme={theme} date={selectedDate} />
            )}
        </div>
    )
}
