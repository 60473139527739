import React from "react"

export default function KoTerm240725({ theme }) {
    return (
        <div className={`terms-ko ${theme === "dark" ? "dark" : ""}`}>
            <p>最終更新日：2024年7月25日</p>
            <p>発効日：2024年8月1日</p>
            <p>一般規制　全体ユーザー</p>
            <ol type="1">
                <li>
                    <p>
                        <strong>当社との関係</strong>
                    </p>
                    <p>
                        CELEBE KOREA CO.,
                        LTDまたはその関連会社（以下、通称して「CELEBe」または「当社」という）が提供するCELEBe（以下、「本プラットフォーム」という）へようこそ。
                        本約款は、当社の関連ウェブサイト、サービス、アプリケーション、製品及びコンテンツ（以下、通称して「本サービス」という）の利用のために、あなたと当社の関係を規定するサービス約款（以下、「本約款」という）です。当社の本サービスは私的で非商業的な目的のために提供しています。本約款で「あなた」とは、あなた及びその他の本サービスのユーザーを指します。
                        本約款は、あなたと当社との間で法的な効力を持つ契約です。以下の内容は、十分な時間を持って注意深くお読みください。
                    </p>
                </li>
                <li>
                    <p>
                        <strong>約款の同意</strong>
                    </p>
                    <p>
                        本約款は、あなたが本約款に同意して、関連するサービスの利用リクエストを送信し当社がそのリクエストを承諾した場合に有効となります。また、当社のサービスにアクセスして利用する場合は、当社のコミュニティのガイドライン及びコミュニティのポリシーに準拠する必要があります。これは本プラットフォームで直接確認するか、または本プラットフォームをダウンロードできるところ、モバイル端末の関連アプリストアでご確認でき、本約款にも参考として含めています。
                        下記の各管轄権のユーザーに該当する追加約款は、あなたが当該約款に同意して、関連サービス利用要求を送信し当社が当該要求を受け入れると効力が発生します。あなたが本サービスにアクセスまたは使用する管轄地域に関連する追加条項と本約款の規定が矛盾する場合は、その管轄区域の追加条項を優先とします。本約款に同意しない場合は、当社の本サービスに接続またはこれを利用してはなりません。本サービスを利用する場合、あなたは個人情報処理方針の規定に同意するものとします。
                        あなたが会社または団体のために本サービスに接続または利用している場合、（a）以下で「あなた」はその会社または団体を含めて、（b）あなたは本約款に拘束力を付与する権利を有している者として、当社または団体の権利を有する代表者であり、その団体を代表して本約款に同意することを表明・保証して、（c）あなたの会社または団体は法的、財政的に本サービスの接続及び利用を含めて、あなたと従業員、代理人、契約者など、あなたに関連する人々があなたのアカウントに接続するか、それを使用することに対して責任があります。
                        あなたは、今後のサービスに接続及び利用すると、約款に同意したとみなされることを理解して同意します。
                        あなたは、保管のために本約款のコピーを印刷または保存するものとします。
                    </p>
                </li>
                <li>
                    <p>
                        <strong>約款の変更</strong>
                    </p>
                    <p>
                        当社は本サービスの機能を更新する場合、当社もしくは系列会社が運営する複数のアプリやサービスを一つの総合サービスやアプリに統合する場合、規制が変更される場合などに随時に変更します。当社は、本約款が重大に変更される場合、商業的に合理的な努力を尽くし、本プラットフォーム上の公知事項、サービス内でのポップアップメッセージ等を通じ、これらの変更を全体ユーザーに一般的に通知します。ただし、あなたは、変更有無を確認するために本約款を定期的に表示する必要があります。あなたが利用約款の変更後に本サービスに接続して利用することは、変更された約款に同意することです。当社の新しい約款に同意しない場合は、本サービスへの接続・利用を中止してください。
                    </p>
                </li>
                <li>
                    <p>
                        <strong>アカウント</strong>
                    </p>
                    <p>
                        本サービスの一部に接続するかこれを利用するためには、アカウントを生成する必要があります。あなたは、アカウントを生成時に正確な最新情報を提供する必要があります。あなたの情報を最新情報で完全に保つためには、当社に提供したあなたの詳細情報及びその他情報を管理して迅速に更新することが重要です。
                        あなたは、アカウントのパスワードを秘密にして第三者に開示してはなりません。第三者があなたのパスワードを知っているか、あなたのアカウントに接続したことを知っているか、それが疑われる場合は、すぐにCELEBeアプリのQ&Aを通じ当社に通知する必要があります。
                        あなたは、あなたのアカウントを通じ行われた活動について（当社及び他のユーザーに対して）全的に責任を負うことに同意します（ただし、CELEBeの故意または重過失によって引き起こされる場合は除く）。
                        当社は、あなたが本約款を遵守していないと判断する合理的な理由がある場合、またはあなたのアカウントでの活動が本サービスに対する被害または損傷を発生させるか、如何なる第三者の権利を侵害したか、如何なる法令または規制に違反しているか、その可能性があると判断された場合は、アカウントを使用できないようにする権利があります。
                        あなたがこれ以上本サービスを利用しなくアカウントの削除を希望する場合は、当社が助けられます。CELEBeアプリのQ&Aを通じ当社にご連絡ください。それに関する手続きをご案内いたします。一応、アカウントを削除するためには、あなたのアカウントを再活性化できなく、あなたが追加したコンテンツまたは情報を取り込むことができません。
                    </p>
                </li>
                <li>
                    <p>
                        <strong>本サービスの接続及び利用</strong>
                    </p>
                    <p>
                        あなたは、本約款と関連法令及び規制に準拠して本サービスに接続して利用します。あなたは、次のような行為をしてはなりません。
                        本約款に同意しないか、または同意する法的能力がないにもサービスに接続するか、これを利用すること。 o
                        ファイル、表、文書（またはその一部）などサービスに基づく2次的著作物を無断でコピー、修正、脚色、翻訳、逆設計、解体、デコンパイルまたは生成するか、本プラットフォームまたは2次的著作物を実装するソースコード、アルゴリズム、手段、または技術を把握・抽出しようとすること
                        o 本サービスまたはその2次的著作物の全部または一部を配布、ライセンス、転送または販売すること o
                        本サービスを有料で販売、レンタル、リースするか、本プラットフォームを広告または商業広報のために利用すること o
                        商業広告や広報またはスパムの伝達もしくは促進など当社の明示的書面同意なしに商業目的や無断でサービスを利用すること o
                        本サービスの正常な動作を妨げたり妨害しようとすること、当社のウェブサイトや本サービスに接続された如何なるネットワークを妨害すること、または本サービスへのアクセスを遮断または制限する当社のすべての措置を迂回すること
                        o
                        本プラットフォームまたはその一部を他のプログラムまたは製品に統合させること。この場合、当社は、裁量によって本サービスの提供を拒否したり、アカウントを削除したり、サービスへのアクセスを制限する権限を有します。
                        o 本サービスから情報を収集するために、またはサービスと相互作用するために、自動化されたスクリプトを使用すること o
                        他人や団体を詐欺したり、あなたがアップロード、投稿、配信、配布、または利用できるようにしたコンテンツが本サービスに由来したという印象を与えるなど、あなたに関して、または他人とか団体との関係に関して偽り明示または間違った表示をする場合
                        o
                        他人を脅かしたり、嫌がらせしたり、わいせつな資料、暴力、または性別、宗教、国籍、障害、性的好みや年齢に基づく差別を促進すること
                        o
                        CELEBeの承認なしに、他人のアカウント、サービス、システムを利用または利用しようとすること、または本サービス上で偽の身元を生成すること
                        o
                        他のユーザーとレビューを取引するか、偽のレビューを作成、要求するなど、本サービスを通じ利益の衝突を発生させるか、本サービスの目的を侵食する方法で利用すること
                        o 以下に規定された対象をアップロード、送信、配布、保存、その他利用可能にするために本サービスを利用すること 
                        ウイルス、トロイの木馬、ワーム、論理爆弾、その他の悪意があるか、または技術的に有害なものを含むファイル 
                        要求されていない、または承認されていない広告、勧誘、広告資料、「ジャンクメール」、「スパムメール」、「チェーンレター」、「多段階」、またはその他の許容されていない勧告
                        
                        第三者の住所、電話番号、電子メール、個人識別文書（国家保険番号、パスポート番号など）の番号や特徴またはクレジットカード番号などの個人情報
                         他人の著作権、商標権、またはその他の知的財産権またはプライバシー権を侵害または侵害する可能性のあるコンテンツ 
                        他人の名誉を毀損、わいせつ、侮辱的、ポルノ、嫌悪、扇動的な資料 
                        刑事上の犯罪、危険な行為または自害行為を構成、奨励、または指導する資料 
                        意図的に、特にトローリングや嫌がらせなどで人々を刺激したり、敵意を引き起こすために、またはいじめたり、危害を加えたり、傷つけたり、怖がったり、苦しめたり、困惑したり、不快にするために作られた資料
                         物理的な暴力を内容とする脅迫など、その他の脅迫を含む資料 
                        人種、宗教、年齢、性別、障害、または性的好みによる差別など、人種主義的または差別的なすべての資料 
                        必要な正規ライセンスを受けていないか、または資格なしに提供される回答、対応、コメント、意見、分析、または勧誘 
                        CELEBeの単独判断によって不快で不適切なもの、他人の本サービスの利用を制限または禁止すること、またはCELEBeの本サービスまたはそのユーザーを如何なる種類の損害や責任にさらす資料
                        上記以外に、あなたの本サービスへの接続及び利用は、いつでもコミュニティのガイドラインに準拠する必要があります。
                        当社は、事前の通知なしにいつでも当社の合理的な裁量で必要と判断した場合、コンテンツを削除したり、コンテンツへのアクセスをブロックすることができます。コンテンツの削除及びアクセスをブロックする理由には、コンテンツが不適切であるか、本約款またはコミュニティのガイドラインに違反した場合、その他本サービス及び本サービスのユーザーに有害であると判断される場合などがあります。当社の自動化されたシステムは、パーソナライズされた検索結果、パーソナライズされた広告など、あなたに個人的に関連する商品特性を提供するか、スパムやマルウェアを検出するためにあなたのコンテンツ（電子メールを含む）を分析します。これらの分析は、コンテンツが送信、受信、及び保存される時に行われます。
                    </p>
                </li>
                <li>
                    <p>
                        <strong>知識財産権</strong>
                    </p>
                    <p>
                        当社は知識財産権を尊重して、あなたも同様にこれを尊重していただくことを要請します。あなたは、本サービスにアクセスして、それを利用する条件で、如何なる知的財産権も侵害しない方法で本サービスに接続し、これを利用することに同意します。当社は、事前通知をせず、いつでも、当社の合理的な裁量で、著作権その他の知的財産権を侵害または侵害するという主張のあるアカウントへのアクセスを防ぐ措置及び/又は削除措置をする権利があります。
                    </p>
                </li>
                <li>
                    <p>
                        <strong>コンテンツ</strong>
                    </p>
                    <p>
                        ユーザーコンテンツを本サービスにまたは本サービスを通じ公開することにより、あなたは当該ユーザーコンテンツに関連するマーケティングまたは広報資料に対する事前検閲または承認に対する権利を放棄します。また、あなたはあなたのユーザーコンテンツまたはその一部に対する個人情報及び宣伝関連権利またはその他の類似した権利のすべてを放棄します。道徳的な権利が譲渡できない範囲で、あなたは、本サービスに、または本サービスを通じ投稿する如何なるユーザーコンテンツにおいて、またはこれに関連するすべての人格的権利を放棄して、あなたが保有する如何なる人格的権利に基づく行動を支援、維持、承認、または主張しないことに同意します。
                        当社は、あなたが本サービスに投稿するか、またはアップロードしたユーザーコンテンツが第三者の知的財産権またはプライバシー権を侵害したという被害当事者の主張がある場合、あなたの身元を当該当事者に公開する権利があります。
                        当社または権限のある第三者は、裁量によって、あなたのコンテンツを切り取ったり編集したり、投稿を拒否することができます。当社は、あなたの本サービス上の投稿が上記の「本サービスの接続及び利用」規定上のコンテンツ基準に準拠していないと判断した場合、あなたの如何なるコンテンツを削除、不許、ブロック、削除する権利を有します。さらに、当社は単独の裁量で、（i）本約款に違反したと判断されるユーザーコンテンツの場合、または（ii）他のユーザーや第三者の苦情に対する応対として、通知もしくは通知なしにあなたに対する責任を負わず、当該コンテンツを削除、不許、ブロック、または削除する義務ではない権利を有します。したがって、当社は、あなたがそのユーザーコンテンツに永久的にアクセスしたい場合は、本サービスに投稿するユーザーコンテンツのコピーをあなたの個人用デバイスに保存することをお勧めします。当社は、如何なるユーザーコンテンツの正確性、完全性、妥当性、または品質を保証するものではなく、ユーザーコンテンツに対して如何なる状況においても如何なる方法でも責任を負いません。
                        あなたは、本サービスにおいて、他のすべてのユーザーがあなたのユーザーコンテンツを公的に利用できるのか、それともあなたが許可した人だけが利用できるかを管理することができます。あなたのコンテンツへのアクセスを制限するには、このプラットフォームでプライバシーの設定を選択する必要があります。
                        当社は、ユーザーから提出され、当社または権限のある第三者が投稿した如何なるコンテンツに対しても責任を負いません（ただし、CELEBeの故意または重過失により生じた場合は除く）。
                        他のユーザーがアップロードした情報に関して不満がある場合は、カスタマーセンターまでお問い合わせください。
                        CELEBeは、権利を侵害するデータを認知した場合、これを本サービスから迅速に除去するために合理的な措置を取ります。他人の著作権または知的財産権を継続的に侵害する本サービスのユーザーのアカウントを適切な状況でその裁量で無効化または解除することは、CELEBeのポリシーです。
                        当社の従業員は継続的に独自のアイデアや機能を開発・評価しており、当社はユーザーコミュニティから受け取る関心、フィードバック、意見及び提案に相当な注目を集めることに自ら誇りを持っています。あなたが当社または当社の従業員に製品、サービス、機能、変更、強化、コンテンツ、改善、スキル、コンテンツ（聴覚、視覚、ゲーム、または他の種類のコンテンツなど）の提供、宣伝、戦略、または製品/機能名または関連文書作業、イラスト、コンピュータコード、ダイアグラム、その他のデータ（以下、総称して「フィードバック」という）に関するアイデアを送付する場合、あなたのコミュニケーション内容にかかわらず、今後誤解を招くことを避けるために、次の条項が適用されます。したがって、あなたは当社にフィードバックを送付することにより、次の事項に同意します。
                        1.
                        CELEBeはあなたのフィードバックを検討、考慮または施行する義務を負わなく、何らかの理由でもフィードバックの全部または一部に対しる返信する義務はありません。
                        2.
                        フィードバックは秘密として提供されるものではなく、当社はあなたが送付するフィードバックを秘密に保つことか、または如何なる方法でもフィードバックを利用または公開することを控える義務はありません。
                        3.
                        あなたは、フィードバック及びその2次的著作物に関して、何らかの目的のために、制限なく、無償で、全部または一部であるか、提供されたままであるか、または変更された状態であるかを問わず、フィードバックに含まれているか実現された商業的著作物、サービスの制作、利用、販売、申請、収入、宣伝するなど、あらゆる種類の複製、配布、2次的著作物の作成、修正、公演（観客基盤を含む）、公衆とのコミュニケーション、可用化、公開展示、その他の利用及び活用できる継続的かつ無制限の承認を当社に取り消すことはできません。
                    </p>
                </li>
                <li>
                    <p>
                        <strong>ポイント</strong>
                    </p>
                    <ol type="a">
                        <li>
                            <p>ポイント獲得</p>
                            <p>
                                CELEBeポイント（以下、「ポイント」という）とは、ユーザーが当社及び提携会社の積立サービス又はイベントなどマーケティング活動等に関して獲得したポイント又は当社を通じ購入したポイントをいいます。
                                当社は以下のようにポイントを算定・付与します。 1. おすすめフィードのビデオを見る場合 2.
                                あなたのビデオが他のユーザーによって見られた場合 3. 出席チェックを完了した場合 4. 今日のミッションを完了する場合 5.
                                「HOME」、「GAME」タブで当社及び提携会社のイベントに参加する場合 6.
                                ショートフォームプレイリスト「Shortply」を生成する場合 7.
                                あなたが生成した「Shortply」が別のユーザーによって見られた場合 8.
                                おすすめのリンクを介しインストールして会員登録をした場合、またはあなたが推奨したユーザーが参加した場合 9. その他
                                ポイントの積立方法及び基準は、当社と提携会社の内部方針により任意に変更されることがあります。
                            </p>
                        </li>
                        <li>
                            <p>ポイントの使用</p>
                            <p>
                                ユーザーは、ゲットしたポイントをfanCウォレットを使ってfanCポイントに交換できますが、1回の交換には15％の手数料がかかります。fanCポイントへの交換以降、fanCウォレット内で提携された様々なポイント、コインに交換できます。
                                fanCウォレット内の提携内容は、他社との協議状況によって変更されることがあります。提携ポイントの交換単価は、提携先からの供給金額、支払手数料、運営費用等を考慮して算定されます。
                                現在のCELEBeポイントをfanCポイントに交換するためには、残りの使用可能ポイントが少なくとも3,000P以上でなければならず、この条件を満たす場合のみ交換が可能です。
                                満14歳未満の未成年者ユーザーがポイントを使用する場合、最初の会員加入時に法定代理人の同意を得て会員登録を完了したもので、ポイント使用に対する法定代理人の同意も得たものとみなします。
                                ユーザーはポイントを他人に譲渡したり、貸付または担保の目的で利用できません。ただし、当社や提携会社で認める「ポイントプレゼント」などの手続きによる場合は例外とします。「ポイントプレゼント」によって、他のユーザーに譲渡または贈与されるポイントは、再び他のユーザーに再譲渡することはできず、当社ポリシーによって当該ポイントの使用有効期間及び譲渡金額の限度/回数の制限がある場合があります。
                            </p>
                        </li>
                        <li>
                            <p>ポイントの訂正、キャンセル及び消滅</p>
                            <p>
                                積立られたポイントにエラーがある場合、ユーザーはエラー発生時点から60日以内に当社に訂正申請をしなければならず、当社はユーザーの訂正申請日から最大90ヶ月以内に訂正手続きを完了しなければなりません。ただし、ユーザーは必要に応じてポイント積立エラーを証明できる客観的なデータを提示しなければなりません。
                                ポイントは、最初の無償で積み立てられた時点から6ヶ月が過ぎた時点の該当月末日に最初に積み立てられたポイントから月単位で自動に消滅されます。有償でチャージしたポイントは有効期間及び消滅予定日がありません。当社は、ユーザーが保有したポイントを消滅予定日が渡来して消滅させる場合、ポイントの消滅と関わる内容を最小15日前にプッシュメッセージ/サービス内のポップアップメッセージなどを通じ案内します。ただし、アプリケーションの削除及び通知受信を拒否して上記のような案内を受信できない場合は不利益が発生することがあります。
                                特定のイベントを通じ提供されたポイントはイベント進行時にポイントの有効期間及び消滅日を別途で案内してプッシュメッセージ/サービス内のポップアップメッセージなどによる消滅案内対象より除外されることがあります。また、ユーザーが当社サービスより該当月の消滅予定ポイントの確認ができるように措置します。
                                ユーザーの購入により積み立てられたポイントではなく、当社や提携会社がイベントなどのマーケティング活動でユーザーに追加で提供したポイントの有効期間は、当社や提携会社のポリシーによって別途策定することがありますが、これは別途公知します。
                                ユーザーが交換したfanCポイントは、交換日基準3日以内でのみ払い戻しが可能で、払い戻し申請日基準の7日以内に復元されます。
                            </p>
                        </li>
                        <li>
                            <p>会員脱退とポイント処理</p>
                            <p>
                                退会を希望するユーザーが残りのポイントを使用したい場合は、会員の退会前日までに積み立てた利用可能ポイントを本約款が定めるところに従って使用する必要があります。
                                退会まで使用しなかったポイントは、一定の猶予期間後に自動消滅されます。
                            </p>
                        </li>
                    </ol>
                </li>
                <li>
                    <p>
                        <strong>賠償</strong>
                    </p>
                    <p>
                        あなたは、あなたまたはあなたのアカウント（あなたへのアクセス権を有するアカウント）のユーザーが本約款、または本約款に従うあなたの義務、陳述及び保証の違反によって発生した弁護士の手数料及び費用を含む一切の請求、責任及び費用及び経費から、CELEBe、その親会社、子会社、関連会社、及び上記の各会社の役員、従業員、代理人及びアドバイザーを守り免責させて、害を与えないことに同意します。
                    </p>
                </li>
                <li>
                    <p>
                        <strong>保証事項の除外</strong>
                    </p>
                    <p>
                        本約款で定める事項は、あなたが契約を通じ変更または放棄できない、消費者に必ず付与される法定権利には影響しません。
                        本サービスは「現状のまま」の状態で提供され、当社は本サービスに関してあなたに記載または保証するものではありません。特に当社はあなたに以下の事項を陳述、保証しません。
                        あなたの本サービスの利用があなたの要件を満たしていること
                        あなたの本サービスの利用に干渉、遅延、セキュリティ上の問題やエラーがないこと
                        あなたが本サービスを利用して取得するすべての情報が正確で信頼できること
                        あなたに本サービスの一部として提供されるソフトウェアの動作または機能の欠陥が修正されること
                        条件、保証、またはその他の規則（満足のいく品質、特定の目的のための適合性、または記載事項の遵守に関するすべての黙示の条件を含む）は、本約款に明示的に示されていない限り、本サービスには適用されません。当社は事業上、運営上の理由により、いつでも公知なしに本プラットフォームの一部または全部を変更、遅延、撤去、その利用を制限することができます。
                    </p>
                </li>
                <li>
                    <p>
                        <strong>責任の制限</strong>
                    </p>
                    <p>
                        本約款は、免責または制限が法的に禁止されている当社の損害賠償責任を免責または制限しません。詐欺または詐欺的虚偽表示による当社の、または当社の従業員、代理人または受給事業者の過失による死亡及び傷害に対する責任に対しても同様です。
                        上記を前提に、当社はあなたに対して以下の責任を負いません。 1.
                        すべての営業上の収益の損失(直接的または間接的に発生した損失を含む)、(II)すべての営業権の損失、(III)すべての機械の損失、(IV)あなたが負ったすべてのデータ損失、または(V)あなたによって発生したすべての間接的または結果的な損失。その他のすべての損失に対する補償はあなたがCELEBeに対して最近12ヶ月間支払った範囲内に制限します。
                        以下の状況であなたによって発生したすべての損失または損害
                        広告の完全性、正確性、または存在に対するあなたの信頼、または本サービスに表示される広告の広告主またはスポンサーとの関係または取引の結果として生じるあなたの信頼
                        当社が本サービスに対して行ったすべての変更、または永久的であるか、または一時的なサービス（またはサービス内の機能）の提供中止
                        あなたの本サービスの利用によって、またはこれを通じ保存または送信されるすべてのコンテンツ及びその他の通信情報の削除、変質、保存失敗
                        あなたが当社に正確なアカウント情報を与えていない
                        あなたがあなたのパスワードまたはアカウント情報のセキュリティ維持及び秘密維持に失敗
                        当社は、本プラットフォームを家庭用の、プライベートな使用のためのみ提供することに注意してください。あなたは、本プラットフォームを商業的または事業目的に使用しないことに同意して、当社はあなたの如何なる営業上の利益の損失、事業上の損失、営業権または事業評判の損失、業務妨害または事業機会の損失に対しても責任を負いません。
                        当社が欠陥のあるデジタルコンテンツをあなたに提供して、あなたの機器やデジタルコンテンツに被害が発生して、上記の被害が合理的な管理と技術的な措置の不足により発生したものである場合、当社はあなたに当該の被害を回復または補償します。ただし、あなたが無料で提供するアップデートを行ってくださいという当社からのアドバイスに従ったら受けなかった被害者、あなたがインストールマニュアルに正確に従わなかったか、当社が提供する最小限のシステム要件を満たさなかったために発生した損害に対しては責任を負いません。
                        上記のような免責事項は、当社がその損失が発生する可能性があることに対してアドバイスを受けたことがあるか、またはその可能性を認識していたはずであるかどうかにかかわらず適用されます。
                        あなたには、当社のサービスを利用する際に課される可能性のあるテキストメッセージ料金とデータ料金を含むすべての携帯電話料金に対して責任があります。どのような料金が適用されるかに関して不明な場合は、本サービスを利用する前に携帯電話のサービス事業者に連絡する必要があります。
                        法令が許す最大の範囲で、あなたの本サービス利用で発生する第三者との紛争（例えば、如何なる通信会社、著作権所有者または他のユーザー等との紛争を含むが、これに限定しない）は、あなたと該当第三者の間に直接存在するものであり、あなたは当該紛争から、またはこれに関連して発生する未知または未知のすべての種類及び性質の請求、要求及び損害(実際の損害及び結果的な損害)から当社及び当社の系列会社をキャンセル不能に免責します。
                    </p>
                </li>
                <li>
                    <p>
                        <strong>その他</strong>
                    </p>
                    <p>
                        <strong>オープンソース</strong>
                    </p>
                    <p>
                        本プラットフォームは特定のオープンソースソフトウェアが含まれています。各オープンソースソフトウェアアイテムは、該当するライセンス条項によって規定されており、その内容はオープンソースポリシーでご確認いただけます。
                    </p>
                    <p>
                        <strong>完全合意</strong>
                    </p>
                    <p>
                        本約款（以下の追加約款を含む）は、あなたとCELEBeの間の完全な法的な合意であり、あなたの本サービスの利用を規定して、本サービスに関してあなたとCELEBeの間にあったすべての既存の合意を置き換えます。
                    </p>
                    <p>
                        <strong>リンク</strong>
                    </p>
                    <p>
                        あなたは、正当かつ法的な方法で、当社の評判を損なうか、またはそれによって利益を得ない方法で当社のホームページをリンクすることができます。あなたは、当社の提携、承認、または保証がないにもかかわらず、これを示唆するための手段としてリンクを作成することはできません。あなたは、あなたが所有していないウェブサイトに当社のプラットフォームリンクを作成してはなりません。あなたがリンクを作成するウェブサイトは、上記の「本サービスのアクセス及び利用」規定のコンテンツ基準をすべて遵守しなければなりません。当社は、事前予告なしにリンクの承認を取り消す権利を有します。
                    </p>
                    <p>
                        <strong>年齢制限</strong>
                    </p>
                    <p>
                        本サービスは、14歳以上の者のみを対象とします（追加約款の管轄区域に基づく条項に規定されている追加の制限事項を含む）。あなたは、本サービスを利用することにより、あなたが関連基準年齢以上であることを確認します。もし、当社が上記の基準年齢未満の者が本サービスを利用していることが分かった場合、当社は当該ユーザーのアカウントを終了させます。
                    </p>
                    <p>
                        <strong>権利放棄の禁止</strong>
                    </p>
                    <p>当社が本約款の条項を履行しないか、または執行しないことは、当該条項または権利を放棄するものと解釈しません。</p>
                    <p>
                        <strong>セキュリティ</strong>
                    </p>
                    <p>
                        当社は、本サービスのセキュリティ状態や本サービスがバグやウイルスから安全であることに対して保証しません。あなたは、本サービスにアクセスする際にあなたの情報技術、コンピュータプログラム、プラットフォームの環境設定を行う責任があります。あなたはあなたのウイルス保護ソフトウェアを使用する必要があります。
                    </p>
                    <p>
                        <strong>可分性</strong>
                    </p>
                    <p>
                        関連事項に関して管轄権を有する裁判所または法律によって本約款の特定条項が無効となった場合、その条項は本約款から削除されますが、これは他の条項に影響を与えず、削除された条項以外の約款条項はそれでも有効で執行可能です。
                    </p>
                    <p>
                        <strong>その他のお問い合わせ</strong>
                    </p>
                    <p>カスタマーセンターのお問い合わせコーナーまでご連絡ください。</p>
                </li>
            </ol>

            <p>
                <strong>追加約款 - アプリストア</strong>
            </p>
            <p>関連法令が許容する範囲内で、特定機器を介して本プラットフォームに接続する場合、以下の追加約款が適用されます。</p>
            <p>
                <strong>Apple関連通知</strong>
            </p>
            <p>
                あなたがアップル株式会社（以下、「Apple」という）が製造した機器から本プラットフォームをダウンロードする時は、以下の事項を認識して同意します。
                本約款は、CELEBeとあなたの間で適用されます。 Appleは本約款の当事者ではありません。
                以下であなたに与えられるライセンスは、Apple機器のうちAppleから承認されたものであり、AppleのApp
                Storeの利用約款に規定された利用規則に従って、個人的・非商業的な利用目的で所有または管理する機器に本プラットフォームを設置できる個人的、制限的、非独占的、譲渡できない権利です。
                Appleは、本プラットフォームまたは本プラットフォームのコンテンツに対して責任を負いません。また、本プラットフォームに関連するサポートサービスを提供または維持する義務はありません。
                本プラットフォームが保証する内容に反する場合、あなたはAppleに通知することができます。Appleは本プラットフォームを購入した価格がある場合、それに相当する価格を払い戻します。法令が許す最大限の範囲内で、Apple
                は本プラットフォームに関して如何なるその他の保証義務も負いません。
                Appleは、本プラットフォームまたはあなたの本プラットフォームの所有及び利用に関するあなたまたは第三者の請求に対して一切の責任を負いません。
                Appleに責任のない請求には、（a）製造物の責任請求、（b）本プラットフォームが関連法令または規制の要件を遵守していないという主張、（c）消費者保護法またはその他の類似法に基づく請求が含まれます。ただし、これに限定しません。
                第三者が本プラットフォーム、またはあなたの本プラットフォームの所有及び利用が自分の知的財産権を侵害すると主張する場合、Appleは、知的財産権侵害の主張を調査、防御、解決、または関連債務を弁済する責任がありません。
                あなたは、あなたが（a）大韓民国政府のエンバゴ政策の適用される国、または大韓民国政府から「テロ支援国」として指定された国にいないこと、（b）あなたが大韓民国政府から禁止または制限された団体に加入していないこと点を表明して保証します。
                AppleとAppleの子会社は、本約款の受益者であり、あなたが本約款の規定及び条件に同意することにより、Appleは、本契約をユーザーに適用する権利を有します（また、Appleは上記の権利に同意したと認めます）。
                CELEBeは、家族共有機能やAppleの他の同様の機能を通じ、複数のユーザーがこのプラットフォームを一緒に使用することを明示的に承認します。
            </p>
            <p>
                <strong>Windows Phone Store</strong>
            </p>
            <p>
                あなたは、マイクロソフト株式会社またはその関連会社が運営するWindows Phone
                Store（またはその承継人）から本プラットフォームをダウンロードすることにより、以下の事項を認識して同意します。
                あなたは、マイクロソフト株式会社、あなたの携帯電話メーカー、及びネットワーク事業者に、本プラットフォームに関連する支援サービスを提供または維持する義務がないことを認識します。
            </p>
            <p>
                <strong>Google Play</strong>
            </p>
            <p>
                あなたは、Google株式会社またはその関連会社（以下「Google」）が運営するGoogle
                Play（またはその承継人）から本プラットフォームをダウンロードすることにより、以下の事項を認識して同意します。 1. Google
                Playの利用約款、Google Playのビジネス及びプログラムポリシー、またはGoogle
                Playのための基本的なエンドユーザーライセンス約款のうち、Googleが指定したこと（以下、総称して「Google
                Playの利用約款」と（b）本約款の他の条項及び条件が反する場合、あなたがGoogle
                Playからダウンロードした本プラットフォームの利用において、Google Play約款を優先とします。
                あなたは、GoogleがCELEBe、またはあなた（または他のユーザー）の本約款またはGoogle
                Play約款の遵守または違反に関して責任を負わないことを認識します。
            </p>
            <p>
                <strong>追加約款 - 管轄区域による規定</strong>
            </p>
            <p>
                <strong>韓国</strong>
            </p>
            <p>あなたが韓国で当社のサービスを利用する場合、以下の追加約款が適用されます。</p>
            <p>
                <strong>適用法と管轄</strong>
            </p>
            <p>
                本約款、本約款による事案及び本約款の制定は大韓民国の法律に従います。あなたと当社はどちらも大韓民国裁判所が専属管轄権を有することに同意します。
            </p>
            <p>
                <strong>責任の制限</strong>
            </p>
            <p>第4条及び第10条の責任の制限条項は、当社の故意又は過失によりあなたに発生した損失及び被害に関しては適用されません。</p>
            <p>
                <strong>親と保護者の同意</strong>
            </p>
            <p>
                あなたが満19歳未満である場合、あなたは親または保護者からサービスを利用するとか、またはサービスアカウントを加入することに同意したことを保証します。
            </p>
            <p>
                <strong>約款の変更</strong>
            </p>
            <p>
                以下の内容は、第3条の規定に優先して適用します。 当社は、関連法令に違反しない範囲で本約款を変更することができまうｓ。
                当社が本約款を変更する場合は、適用日時及び修正事由を明示して、現行約款とともにホームページの初期画面にその適用日の7日前から適用日の前日まで通知します。ただし、本約款の重大な変更またはユーザーに不利な変更がある場合、当社は合理的な努力を尽くしてユーザーに事前通知を提供して、変更された約款は少なくとも30日後に施行されます。ただし、あなたの利便性を向上させるサービスの新機能に関する変更や法的な理由による変更は、直ちに実施されることがあります。
                上記のように変更された約款を公知または通知しながら、あなたに上記の期間内に意思表示をしない場合、意思表示が表明されたものとみなす旨を明確に公知または通知したにもかかわらず、あなたが明示的に拒否の意思表示をしなかった場合、変更約款に同意したとみなします。
            </p>
            <p>
                <strong>本サービスの利用</strong>
            </p>
            <p>
                以下の内容は、第5条の規定に優先して適用します。
                コンテンツが不快または不適切な場合、本約款に違反した場合、またはその他のサービス及びサービスのユーザーに有害であると思われる場合を除き、あなたの同意や法的根拠なしにユーザーコンテンツを削除したり、そのコンテンツへのアクセスを妨げたりすることはありません。
            </p>
            <p>
                <strong>ユーザー制作コンテンツ</strong>
            </p>
            <p>
                以下の内容は、第7条Bの規定に優先して適用します。
                当社は、法律で許容される範囲内であなた及び他のユーザーに本サービスを提供して、本プラットフォームの広報及び改善のために当社のライセンスに従い、ユーザーコンテンツを利用、修正、脚色、複製、2次的な著作物の作成、出版及び/又は転送、本サービスの他のユーザー及び他の第三者が上記のコンテンツを利用、修正、脚色、複製、2次的な著作物の作成、出版及び/又は転送可能にすることができます。
                当社は、法律上において特に許容されるか、またはあなたの同意がある場合を除き、あなたの身元を如何なる第三者にも開示しません。
                あなたのユーザーコンテンツが上記の「本サービスのアクセス及び利用」の規定に規定されている基準を満たしていないと判断された場合、当社または権限のある第三者はそのユーザーコンテンツを切り取ったり編集したり、または投稿を拒否できます。
            </p>
            <p>
                <strong>サービスの利用制限等に関する事前通知</strong>
            </p>
            <p>
                以下の内容は、第5条、第6条、第7条、第9条の規定に優先して適用します。
                当社は、あなたに不利な変更を加えた場合（本サービスに対するすべての遅延または利用の制限、アカウントの解除及び削除、コンテンツの削除、不許、ブロックをすべて含む）、該当措置の理由を遅滞なく通知します。ただし、通知が法的な理由で禁止されたり、ユーザー、第三者、当社及びその系列会社に危害を引き起こす可能性があると合理的に判断される場合（例えば、通知することが法令又は規制当局の命令に違反する場合、調査を妨害する場合、本サービスのセキュリティを害する場合など）には通知をしないことがあります。
            </p>
            <p>
                <strong>約款の同意</strong>
            </p>
            <p>
                以下の内容は、第2条の規定に優先して適用します。
                あなたが本約款に同意しても個人情報処理方針に同意したわけではありません。あなたは本約款の同意とは別に個人情報処理方針に同意しなければなりません。
            </p>
        </div>
    )
}
