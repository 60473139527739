import background1 from "../../assets/point-guide/KOR_background1.png"
import background2 from "../../assets/point-guide/KOR_background2.png"
import background3 from "../../assets/point-guide/KOR_background3.png"
import background4 from "../../assets/point-guide/KOR_background4.png"
import background5 from "../../assets/point-guide/KOR_background5.png"

import PHbackground1 from "../../assets/point-guide/ph/PH_background1.png"
import PHbackground2 from "../../assets/point-guide/ph/PH_background2.png"
import PHbackground3 from "../../assets/point-guide/ph/PH_background3.png"
import PHbackground4 from "../../assets/point-guide/ph/PH_background4.png"
import PHbackground5 from "../../assets/point-guide/ph/PH_background5.png"

import INHINDIbackground1 from "../../assets/point-guide/in/INHINDI_background1.png"
import INHINDIbackground2 from "../../assets/point-guide/in/INHINDI_background2.png"
import INHINDIbackground3 from "../../assets/point-guide/in/INHINDI_background3.png"
import INHINDIbackground4 from "../../assets/point-guide/in/INHINDI_background4.png"
import INHINDIbackground5 from "../../assets/point-guide/in/INHINDI_background5.png"

import THbackground1 from "../../assets/point-guide/th/TH_background1.png"
import THbackground2 from "../../assets/point-guide/th/TH_background2.png"
import THbackground3 from "../../assets/point-guide/th/TH_background3.png"
import THbackground4 from "../../assets/point-guide/th/TH_background4.png"
import THbackground5 from "../../assets/point-guide/th/TH_background5.png"

import VNbackground1 from "../../assets/point-guide/vn/VN_background1.png"
import VNbackground2 from "../../assets/point-guide/vn/VN_background2.png"
import VNbackground3 from "../../assets/point-guide/vn/VN_background3.png"
import VNbackground4 from "../../assets/point-guide/vn/VN_background4.png"
import VNbackground5 from "../../assets/point-guide/vn/VN_background5.png"

import IDbackground1 from "../../assets/point-guide/id/ID_background1.png"
import IDbackground2 from "../../assets/point-guide/id/ID_background2.png"
import IDbackground3 from "../../assets/point-guide/id/ID_background3.png"
import IDbackground4 from "../../assets/point-guide/id/ID_background4.png"
import IDbackground5 from "../../assets/point-guide/id/ID_background5.png"

import { useNavigate } from "react-router-dom"
import { useEffect, useState } from "react"

import Back from "../../assets/drawable-xxhdpi/backbutton.svg"

export default function PoingGuide() {
    const navigate = useNavigate()

    const [country, setCountry] = useState("ko")
    const [lang, setLang] = useState("ko")
    const [loading, setLoading] = useState(false)

    useEffect(() => {
        const uri = new URL(window.location)

        const langValue = uri.searchParams.get("lang")
        if (langValue) setLang(langValue)

        const countryValue = uri.searchParams.get("country")
        if (countryValue) setCountry(countryValue)

        setLoading(true)
    }, [])

    const getBackgroundByCountryAndLang = (country, lang, step) => {
        if (country === "in" && lang === "hi") {
            return {
                1: INHINDIbackground1,
                2: INHINDIbackground2,
                3: INHINDIbackground3,
                4: INHINDIbackground4,
                5: INHINDIbackground5,
            }[step]
        }

        const countryBackgroundMap = {
            ko: {
                1: background1,
                2: background2,
                3: background3,
                4: background4,
                5: background5,
            },
            ph: {
                1: PHbackground1,
                2: PHbackground2,
                3: PHbackground3,
                4: PHbackground4,
                5: PHbackground5,
            },
            in: {
                1: PHbackground1,
                2: PHbackground2,
                3: PHbackground3,
                4: PHbackground4,
                5: PHbackground5,
            },
            th: {
                1: THbackground1,
                2: THbackground2,
                3: THbackground3,
                4: THbackground4,
                5: THbackground5,
            },
            vn: {
                1: VNbackground1,
                2: VNbackground2,
                3: VNbackground3,
                4: VNbackground4,
                5: VNbackground5,
            },
            id: {
                1: IDbackground1,
                2: IDbackground2,
                3: IDbackground3,
                4: IDbackground4,
            },
        }

        return (countryBackgroundMap[country] || countryBackgroundMap.ko)[step]
    }

    return loading ? (
        <div className="point-guide" style={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
            <img
                src={Back}
                style={{ position: "absolute", top: 10, right: 10, width: 30, height: 30, zIndex: 10, padding: 1 }}
                alt={""}
                onClick={() => window.history.back()}
            />
            {[1, 2, 3, 4, 5].map((step, idx) => (
                <img
                    key={idx}
                    src={getBackgroundByCountryAndLang(country, lang, step)}
                    style={{ width: "100%", maxWidth: "800px", height: "auto", marginBottom: "-1px", cursor: step > 1 ? "pointer" : "auto" }}
                    alt={""}
                    onClick={() => {
                        if (step > 1) navigate(`/point-guide/${step - 1}?country=${country}&lang=${lang}`)
                    }}
                />
            ))}
        </div>
    ) : (
        <div></div>
    )
}
